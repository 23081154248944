import { KonvaEventObject } from "konva/lib/Node";
import { Stage } from "konva/lib/Stage";
import { Vector2d } from "konva/lib/types";
import { useEffect, useRef, useState } from "react";
import { Circle, Group } from "react-konva";
import { AnchorType, Shape } from "../../contexts/DrawerTypes";

const EditorPoint = ({
    shape,
    anchors,
    rectColor,
    radius,
    handleUpdateEditShape,
    handleContext,
}: {
    shape: Shape,
    anchors: AnchorType[]
    rectColor: string,
    radius: number,
    handleUpdateEditShape: (anchors: AnchorType[]) => void,
    handleContext?: (e: KonvaEventObject<PointerEvent>) => void,
}) => {

    const { id, isComplete } = shape;

    const [stage, setStage] = useState<Stage | null>(null);
    const [pointCenter, setPointCenter] = useState({ x: 0, y: 0 });

    const circleRef = useRef<any>();

    const handleGroupMouseOut = (e: KonvaEventObject<PointerEvent>) => {
        e.target.getStage()!.container().style.cursor = "default";
    };

    const handleGroupMouseOver = (e: KonvaEventObject<PointerEvent>) => {
        e.target.getStage()!.container().style.cursor = "move";
        if (e.target.getStage()) {
            setStage(e.target.getStage());
        }
    };
    const handleAnchorContext = (e: KonvaEventObject<PointerEvent>) => {
        e.evt.preventDefault()
        return handleContext && handleContext(e)
    };
    const groupDragBound = (pos: Vector2d) => {

        let { x, y } = pos;

        if (stage) {
            const sw = stage.width();
            const sh = stage.height();
            if (y - radius < 0) y = radius;
            if (x - radius < 0) x = radius;
            if (y + radius > sh) y = sh - radius;
            if (x + radius > sw) x = sw - radius;
            return { x, y };
        } else {
            return { x, y }
        }
    };

    useEffect(() => {
        const anchor = anchors[0];
        setPointCenter({ x: anchor.x, y: anchor.y });
    }, [id]);

    return (
        <Group
            id={id}
            draggable={isComplete}
            onMouseOut={handleGroupMouseOut}
            //dragBoundFunc={groupDragBound}
            onContextMenu={handleAnchorContext}
        //onDragEnd={handleGroupDragEnd}
        >
            <Circle
                id={id}
                onMouseOver={handleGroupMouseOver}
                dragBoundFunc={groupDragBound}
                ref={circleRef}
                x={pointCenter.x}
                y={pointCenter.y}
                radius={radius}
                fill={rectColor}
                draggable={true}
                strokeWidth={3}
                stroke={rectColor}
                onDragEnd={(e) => {
                    const { x, y } = e.target.getAttrs();
                    setPointCenter({ x, y });
                    handleUpdateEditShape([{ index: '0', x: x, y: y },])
                }}
                onTransformEnd={() => {
                    const node = circleRef.current;
                    node.scaleX(1);
                    node.scaleY(1);
                    const box = {
                        x: node.x(),
                        y: node.y(),
                    }
                    const newAnchors = [
                        { index: '0', x: box.x, y: box.y },
                    ]

                    setPointCenter({ x: box.x, y: box.y });
                    handleUpdateEditShape(newAnchors)
                }}
            />

        </Group>
    )
}

export default EditorPoint