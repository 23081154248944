
import { KonvaEventObject } from 'konva/lib/Node';
import { AnchorType, Shape } from '../../contexts/DrawerTypes';
import EditorCircle from './EditorCircle';
import EditorPoint from './EditorPoint';
import EditorPolygon from './EditorPolygon';
import EditorRect from './EditorRect';

const EditorMiddleware = ({
    shape, rectColor, anchors, radius, fill, handleUpdateEditShape, handleContext
}: {
    shape: Shape,
    rectColor: string,
    anchors: AnchorType[],
    fill: boolean,
    radius: number,
    handleUpdateEditShape: (anchors: AnchorType[]) => void,
    handleContext?: (e: KonvaEventObject<PointerEvent>) => void,
}) => {

    switch (shape.type) {
        case 'circle':
            return <EditorCircle
                shape={shape}
                anchors={anchors}
                rectColor={rectColor}
                fill={fill}
                handleUpdateEditShape={handleUpdateEditShape}
                handleContext={handleContext}
            />
        case 'bbox':
        case 'rect':
            return <EditorRect
                shape={shape}
                anchors={anchors}
                rectColor={rectColor}
                fill={fill}
                handleUpdateEditShape={handleUpdateEditShape}
                handleContext={handleContext}
            />
        case 'polygon':
            return <EditorPolygon
                shape={shape}
                anchors={anchors}
                rectColor={rectColor}
                fill={fill}
                handleUpdateEditShape={handleUpdateEditShape}
                handleContext={handleContext}
            />;
        case 'point':
            return <EditorPoint
                shape={shape}
                anchors={anchors}
                radius={radius}
                rectColor={rectColor}
                handleUpdateEditShape={handleUpdateEditShape}
                handleContext={handleContext}
            />
        default:
            return null;
    }
}

export default EditorMiddleware