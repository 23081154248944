import styled from "@emotion/styled";
//import logoUrl from "../../assest/logo.png";
import logoUrl from "../../assest/mic.logo.svg";
import blogoUrl from "../../assest/fst.logo.svg";
import aflogoUrl from "../../assest/aiotxfst.logo.png";
//import { fstlogo } from "../../urls/index";
import { NavLink } from "react-router-dom";
import { Typography } from "antd";
import AvatarLogo from "../Avatar/index";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const { Text } = Typography;

const Container = styled.div`
  width: 100%;
  min-height: 1250px;
  background-color: white;
`;
const Header = styled.div`
  width: 100%;
  height: 4rem;
  position: sticky;
  padding: 3px 5px;
  top: 0;
  z-index: 3;
  background-color: white;
  display: flex;
  align-items: center;
`;
const Logo = styled.div`
  height: 100%;
  img {
    width: auto;
    height: 100%;
  }
`;
const Navigation = styled.nav`
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;
const CustomNavLink = styled(NavLink)`
  padding: 3px 5px;
  font-weight: 600;
  span {
    font-weight: inherit;
    color: inherit;
  }
`;

export interface LayoutProps {}

const Layout: React.FC<LayoutProps> = ({ children }) => {

  const { systemLogo } = useTypedSelector(
    (state) => state.auth
  );

  let systemlogo = systemLogo || 1;

  return (
    <Container>
      <Header>
        <Logo>

        {systemlogo === 1 &&
            <img src={blogoUrl} alt="logo"  style={{ marginLeft: "0.8rem", height: "80%" }} />
        }
        {systemlogo === 2 &&
            <img src={logoUrl} alt="logo"  style={{ width: "7rem", marginLeft: "1rem" }} />
        }
        {systemlogo === 3 &&
            <img src={aflogoUrl} alt="logo"  style={{  marginLeft: "1rem" }} />
        }        
{/*           {fstlogo &&
            <img src={blogoUrl} alt="logo"  style={{ marginLeft: "0.8rem" }} />
          }
          {!fstlogo &&
            <img src={logoUrl} alt="logo"  style={{ width: "7rem", marginLeft: "1rem" }} />
          } */}
        </Logo>
        <Navigation>
          <CustomNavLink
            activeStyle={{ color: "blueviolet", fontWeight: 700 }}
            to="/home"
          >
            <Text>Home</Text>
          </CustomNavLink>
          {/*
          <CustomNavLink
            activeStyle={{ color: "blueviolet", fontWeight: 700 }}
            to="/home/servers"
          >
            <Text>Server Manager</Text>
          </CustomNavLink>
          */}       
          {/*
          <CustomNavLink
            activeStyle={{ color: "blueviolet", fontWeight: 700 }}
            to="/online-predict"
          >
            <Text>Online Predict</Text>
          </CustomNavLink>
          */}
        </Navigation>
        <AvatarLogo />
      </Header>
      {children}
    </Container>
  );
};

export default Layout;
