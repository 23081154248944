import { DeleteFilled, EditOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Badge, Button, Popover, Select } from "antd";
import { useMemo, useState } from "react";
import { useCanvas } from "../../contexts/DrawerContext";
import { Shape } from "../../contexts/DrawerTypes";

const ShapeCombinedBox = ({
    index,
    shape,
    onSelected,
    onDeleteShape,
    onSelectShape,
    onVisibleShape,
    //cancelSelect,
    labelColor
}: {
    index: number,
    shape: Shape,
    onSelected: boolean
    onDeleteShape: (shapeId: string) => void;
    onVisibleShape: (shape: Shape) => void;
    onSelectShape: (shape: Shape) => void,
    cancelSelect?: () => void,
    labelColor?: string

}) => {

    const { canvasState, setShapeClassificationId } = useCanvas();
    const { classifications, classSummary } = canvasState;
    const [hover, setHover] = useState(false);

    const handleSelectClassification = (value: number) => {
        setShapeClassificationId(shape.id, value);
    };

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault()
        return onSelectShape(shape)
    };

    const classLabel = useMemo(() => {
        const target = classifications.find(ele => ele.id === shape.classificationId);
        const summary = classSummary.find(item => item.class === target?.cid);
        return {
            label: summary?.classlabel,
            color: summary ? summary.color : undefined
        };

    }, [classifications, shape, classSummary]);

    return (
        <Badge.Ribbon
            placement='start'
            text={`${index + 1}: ${classLabel?.label || 'Empty Class'}`}
            color={classLabel?.color || labelColor}
            style={{ zIndex: 999 }}
        >
            <div
                onMouseOver={(e) => {
                    e.currentTarget.style.cursor = "pointer"
                }}
                style={{
                    border: onSelected || hover ? '3px solid rgb(188, 34, 230,0.8)' : '1px solid rgb(10,10,10,0.3)',
                    borderRadius: 10, marginTop: 5,
                    position: 'relative', padding: 10, display: 'flex', height: 100, width: 180, zIndex: 99
                }}
            >

                <div
                    onClick={handleClick}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    style={{
                        position: 'relative', paddingTop: 15,
                        width: '62%', margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center',
                        background: 'white', border: '0px solid', zIndex: 10,
                    }}>
                    <div style={{ background: classLabel?.color || labelColor, width: 30, height: 30, borderRadius: '100%', display: 'grid', }} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: 2, border: '1px solid rgba(0,0,0,0.2)', padding: 5, borderRadius: 5 }}>
                    <Button
                        onClick={() => onDeleteShape(shape.id)} size='small'
                        icon={<DeleteFilled style={{ color: '#e73227 ' }} />}
                    />
                    <Button
                        disabled={onSelected} size='small'
                        onClick={() => onVisibleShape(shape)}
                        icon={shape.visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                    />
                    <Popover content={
                        <div style={{ width: 240, wordWrap: 'break-word', textAlign: 'center' }}>
                            <h3>Select ClassSummary</h3>
                            <Select
                                size='middle'
                                onChange={handleSelectClassification}
                                style={{ width: '100%' }}
                                value={shape.classificationId}
                                options={[...classifications].map(item => {
                                    return {
                                        label: item.description,
                                        value: item.id
                                    }
                                })}
                            />
                        </div>
                    } title='Anchor JSON' trigger="click" placement='left'>
                        <Button size='small'
                            icon={<EditOutlined />}
                        />
                    </Popover>
                </div>
            </div>
        </Badge.Ribbon>
    )
}

export default ShapeCombinedBox