import { ActionTypes } from "../action-types";
import * as Actions from "../actions";

interface AuthState {
  systemLogo: number;
  currentUser: string;
  currentPermission: string;
  error: string;
  isAuthPending: boolean;
  expiredDate: number | null;
  signUpSuccess: boolean;
}

const INITAIL_STATE: AuthState = {
  systemLogo: 0,
  currentUser: "",
  currentPermission: "",
  error: "",
  isAuthPending: false,
  expiredDate: null,
  signUpSuccess: false,
};

const reducer = (state = INITAIL_STATE, action: Actions.AuthAction) => {
  switch (action.type) {    
    case ActionTypes.LOAD_CONFIG:
      return {
        ...state,
        systemLogo: action.systemLogo,
      };    
    case ActionTypes.LOGIN_START:
      return {
        ...state,
        isAuthPending: true,
        error: "",
        currentUser: "",
        currentPermission: {},
      };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        currentUser: action.username,
        currentPermission: action.userpermission,
        isAuthPending: false,
        error: "",
        expiredDate: action.expiredDate,
      };
    case ActionTypes.LOGIN_ERROR:
      return {
        ...state,
        isAuthPending: false,
        error: action.error,
        currentUser: "",
        currentPermission: {},
      };
    case ActionTypes.SIGNUP_START:
      return {
        ...state,
        isAuthPending: true,
        error: "",
      };
    case ActionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        isAuthPending: false,
        error: "",
        signUpSuccess: true,
      };
    case ActionTypes.SIGNUP_ERROR:
      return {
        ...state,
        isAuthPending: false,
        error: action.error,
      };
    case ActionTypes.CLEAR_SIGNUP:
    case ActionTypes.LOGOUT:
      return {
        currentUser: "",
        currentPermission: {},
        error: "",
        isAuthPending: false,
        expiredDate: null,
        signUpSuccess: false,
      };
    case ActionTypes.CLEAR_ERROR:
      return {
        ...state,
        error: "",
      };
    default:
      return state;
  }
};

export default reducer;
