
import styled from '@emotion/styled';

import { Button, Popconfirm } from 'antd';

import { useCanvas } from '../../contexts/DrawerContext';
import { Shape } from '../../contexts/DrawerTypes';
import { offlineUrlExpress } from '../../urls';
import ShapeBox from './ShapeBox';


const ScrollViewer = styled.div`
        width: 100%;
    padding: 12px;
    max-height: 75vh;
    display: flex;
    flex-direction: column;
    gap: 18px;
    overflow-y: auto;
    ::-webkit-scrollbar{
        width: 10px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
        background: rgb(100,100,100,0.4);
        border-radius:5px
    }
    ::-webkit-scrollbar-thumb:hover {
        background: rgb(100,100,100,0.8);
    }
`;

const ShapeList = ({ labelColor }: { labelColor: string }) => {

    const { canvasState, deleteShapeById, selectCurrentShape, setVisibleShapeBox, clearCanvasElement } = useCanvas()
    const { shapes, currentImage, selectShape } = canvasState;;

    const handleDeleteShape = (shapeId: string) => {
        deleteShapeById(shapeId)
    };

    const handleSelectShape = (shape: Shape) => {
        selectCurrentShape(shape)
    };

    const handleVisible = (shape: Shape) => {
        setVisibleShapeBox(shape)
    };

    const confirmClear = () => {
        clearCanvasElement()
    };

    return (
        <div
            style={{
                textAlign: 'center',
                width: 250, border: '0px solid', height: '100%', borderRadius: 10,
                paddingTop: 10,
                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
            }}
        >
            <div style={{ display: 'flex', gap: 8, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ fontSize: 18, fontWeight: 'bold' }}>
                    Shapes View
                </div>
                <Popconfirm
                    title="Are you sure to clear canvas?"
                    onConfirm={confirmClear}
                    okText="Clear"
                    cancelText="Cancel"
                    disabled={shapes.length === 0}
                >
                    <Button type='text' danger disabled={shapes.length === 0}>Clear</Button>
                </Popconfirm>
            </div>

            <ScrollViewer >
                {currentImage?.url &&
                    shapes.filter(shape => !shape.binding).map((shape, index) => {
                        return <ShapeBox
                            key={index}
                            index={index}
                            imageSource={`${offlineUrlExpress}/api/get-image?url=${currentImage?.url}`}
                            shape={shape}
                            labelColor={labelColor}
                            onSelected={shape.id === selectShape?.id}
                            onDeleteShape={handleDeleteShape}
                            onVisibleShape={handleVisible}
                            onSelectShape={handleSelectShape}
                        />
                    }
                    )}
            </ScrollViewer>
        </div>
    );
}

export default ShapeList