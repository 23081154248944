import { AimOutlined, EditOutlined, LeftSquareOutlined, PlusSquareOutlined, QuestionCircleFilled, UndoOutlined } from "@ant-design/icons";
import { Button, Divider, Modal, Tooltip, message } from "antd";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import useImageBase64 from "../../hooks/useImageBase64";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { offlineUrlExpress } from "../../urls";
import LabelSyntheticCanvas from "../Label-Synthetic-Canvas";
import LabelSyntheticForm, { CategorySetting, FormDataType, GenerationOptionType, initFormData } from "../Label-Synthetic-Form";
import { AnnotationType } from "../Label-Synthetic-Rectangle";
import LabelSyntheticStatus from "../Label-Synthetic-Status";


const statusColor = {
    error: '#EE1A10',
    warn: '#F4D03F',
    ok: '#29CB66'
};

function formatBodyData(data: FormDataType, options: GenerationOptionType) {
    const {

        countRange,
        sizeRange,
        alphaRange,
        rotationRange,
        clusterRatio
    } = options;
    return {
        ...data,
        // generationObjectCountMinRange: (countRange && data.generationObjectCountMinRange) || null,
        // generationObjectCountMaxRange: (countRange && data.generationObjectCountMaxRange) || null,
        generationObjectSizeMin: (sizeRange && data.generationObjectSizeMin) || null,
        generationObjectSizeMax: (sizeRange && data.generationObjectSizeMax) || null,
        generationObjectAlphaMin: (alphaRange && data.generationObjectAlphaMin) || null,
        generationObjectAlphaMax: (alphaRange && data.generationObjectAlphaMax) || null,
        generationObjectRotationMin: (rotationRange && data.generationObjectRotationMin) || null,
        generationObjectRotationMax: (rotationRange && data.generationObjectRotationMax) || null,
        clusterRatio: (clusterRatio && data.clusterRatio) || null
    }
};

const LabelSyntheticModal = ({
    imageUrl, datasetId, imageName
}: {
    imageUrl: string, datasetId: number, imageName: string
}) => {
    // const [selectedColor, setSelectedColor] = useState<string>(colors[0]);
    const [show, setShow] = useState(false);

    const [annotations, setAnnotations] = useState<AnnotationType[]>([]);

    const [canvasImage, setCanvasImage] = useState<HTMLImageElement | undefined>(undefined);
    const [container, setContainer] = useState<[number, number]>([0, 0]);
    const [onLabel, setOnLabel] = useState(false);
    const [onCircle, setOnCircle] = useState(false);
    const [onEdit, setOnEdit] = useState(false);
    //const [currentRect, setCurrentRect] = useState<string | null>();
    const [natureImageSize, setNatureImageSize] = useState([0, 0]);
    const baseImage = useImageBase64(imageUrl);

    const [tempForm, setTempForm] = useState<FormDataType | null>(null);//暫存form內容

    const [onQueryStack, setOnQueryStack] = useState<{
        processId: string, datasetId: string, count: number, originAnn: AnnotationType[]
    } | undefined>(undefined);

    const { currentUser, currentPermission } = useTypedSelector((state) => state.auth);

    const userPermission = JSON.parse(atob(currentPermission));

    const { autolabelFunction, autorunFunction, classifyFunction, segmentationFunction, keypointFunction, abnormalFunction, yoloFunction, angleFunction, smalldefectFunction, disgFunction } = userPermission;

    const handleClose = () => {
        setTempForm(null);
        setShow(false);
        setAnnotations([]);
        setOnLabel(false);
        setOnCircle(false);
        setOnQueryStack(undefined)
    };

    const handleGoLabel = () => {
        const nextLabel = !onLabel;
        if (nextLabel) {
            setOnCircle(false)
            setOnEdit(false)
        }
        setOnLabel(nextLabel)
    };
    const handleGoCircle = () => {
        const nextCircle = !onCircle;
        if (nextCircle) {
            setOnLabel(false);
            setOnEdit(false)
        }
        setOnCircle(nextCircle)
    };

    const handleOffAction = () => {
        setOnCircle(false)
        setOnLabel(false);
    };

    const rectColor = statusColor.ok;

    useLayoutEffect(() => {
        const img = new Image();
        img.onload = (e) => {
            const { naturalWidth, naturalHeight } = e.target as HTMLImageElement;
            setNatureImageSize([naturalWidth, naturalHeight]);
            const rh = 800;
            const rw = rh * (naturalWidth / naturalHeight);
            const w = rw > 1000 ? 1000 : rw;
            const h = rh * (w / rw);
            img.width = w;
            img.height = h;
            setContainer([img.width, img.height]);
            setCanvasImage(img);
        };
        img.src = imageUrl;
        return () => {
            img.onload = null;
        };
    }, [imageUrl]);


    function roundToTwo(num: any) {
        return parseFloat((Math.round(num * 100) / 100).toFixed(2));
    }

    const onSubmit = async (data: FormDataType, cla: CategorySetting[], options: GenerationOptionType) => {
        const [cWidth, cHeight] = container;
        const [nWidth, nHeight] = natureImageSize;
        const xScale = nWidth / cWidth;
        const yScale = nHeight / cHeight;
        const annotationInScale = annotations.map(rect => {
            const { x, y, width, height, type } = rect
            if (type === 'circle') {
                const r = width * xScale;
                return {
                    ...rect,
                    x: x * xScale,
                    y: y * yScale,
                    width: r,
                    height: r
                }
            } else {
                return {
                    ...rect,
                    x: x * xScale,
                    y: y * yScale,
                    width: (width) * xScale,
                    height: (height) * yScale
                }
            }
        });
        const regions = annotationInScale.filter(ann => ann.type === 'rect');
        const circles = annotationInScale.filter(ann => ann.type === 'circle');
        const regionCoordinates = regions.map(ann => `${roundToTwo(ann.x)},${roundToTwo(ann.y)},${roundToTwo(ann.x + ann.width)},${roundToTwo(ann.y + ann.height)}`);
        const clusterPoint = regions.map(rect => {
            const circle = circles.find(c => c.rectId === rect.id);
            return circle ? `${roundToTwo(circle.x)},${roundToTwo(circle.y)}` : null
        });
        const clusterRadius = regions.map(rect => {
            const circle = circles.find(c => c.rectId === rect.id);
            return circle ? `${roundToTwo(circle.width)}` : null
        });

        function convertClassToArray(data: CategorySetting[]) {
            const checked = data.filter(ele => ele.checked);
            const classes1 = checked.map(ele => ele.classes === 'None' ? null : ele.classes);
            const category1 = checked.map(ele => ele.label);
            return {
                'class': classes1,
                'category': category1
            }
        }

        const formatBody = formatBodyData(data, options);
        const formCla = convertClassToArray(cla);
        const body = {
            ...formatBody,
            ...formCla,//convertClassToArray(formatBody?.class, formatBody?.category.length),
            token: localStorage.getItem("jwt"),
            datasetId: datasetId,
            'generationCoordinates': regionCoordinates,
            'clusterPoint': clusterPoint,
            'clusterRadius': clusterRadius,
            images: [{
                imagefilename: imageName,
                image: baseImage
            }]
        };
        try {
            const res = await fetch(`${offlineUrlExpress}/api/disg/generateImage`, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain, */*', }),
                body: JSON.stringify(body)
            });
            const status = await res.json();
            if (status) {
                if (status.processId === "null") {
                    return message.error('Generate failed.')
                };
                setTempForm(data);
                setOnQueryStack({
                    processId: status.processId,
                    datasetId: datasetId.toString(),
                    count: status.generationImageCount,
                    originAnn: annotationInScale
                })
            };
        } catch (err: any) {
            message.error(err.message);
        }

    };

    const handleClear = () => {
        setAnnotations([])
    }

    const handleEdit = () => {
        const nextEdit = !onEdit;
        if (nextEdit) {
            setOnCircle(false);
            setOnLabel(false);
        }
        setOnEdit(nextEdit);
    }

    const onCluster = useMemo(() => {
        return annotations.filter(ann => ann.type === 'circle').length > 0;
    }, [annotations])

    useEffect(() => {
        setOnLabel(true)
    }, [imageUrl]);

    // useEffect(() => {
    //     console.log(annotations)
    // }, [annotations]);

    const confirmEnabled = useMemo(() => {
        //如果什麼都沒畫，無法submit
        if (annotations.length === 0) {
            return false
        }
        const unQualify = annotations.some(ann => !ann.qualified);
        //如果有不合格的rect，無法submit
        if (unQualify) {
            return false;
        }
        const regions = annotations.filter(ann => ann.type === 'rect');
        const circles = annotations.filter(ann => ann.type === 'circle');
        //如果有circle但數量和rect不符，無法submit
        if (circles.length !== 0 && circles.length > regions.length) {
            return false
        }
        return true

    }, [annotations]);

    return (
        <>
            {disgFunction &&
                <Button
                    style={{ width: "100%" }}
                    onClick={() => setShow(true)}
                >
                    Synthetic Defect [Y]
                </Button>
            }
            <Modal
                title={
                    <div style={{ display: 'flex', gap: 10, alignContent: 'center' }}>
                        <h3 >Label Image Synthetic</h3>
                        <Tooltip
                            color={'white'}
                            placement="bottom"
                            title={<div>
                                <h3>操作說明</h3>
                                <ul style={{ color: 'black', letterSpacing: 1, padding: 1 }}>
                                    <li>
                                        label range: 畫框範圍(方形)
                                    </li>
                                    <li>
                                        label target: 框內生成範圍(圓形)
                                    </li>
                                    <li>
                                        框中點擊右鍵可選擇是否刪除
                                    </li>
                                    <li>
                                        框的過程中右鍵點擊可關閉畫框操作
                                    </li>
                                    <li>
                                        一個框內只能有一個圈
                                    </li>
                                </ul>
                            </div>}>
                            <QuestionCircleFilled />
                        </Tooltip>
                    </div>
                }
                centered
                open={show}
                onOk={undefined}
                footer={null}
                onCancel={handleClose}
                cancelText='Close'
                width={container[0] + 800}
            >
                {onQueryStack ? <div>
                    <LabelSyntheticStatus {...onQueryStack} onBack={() => setOnQueryStack(undefined)} />
                </div> :
                    <div style={{
                        border: '0px solid', display: 'flex', justifyContent: 'space-between', gap: 12, height: '100%'
                    }}>

                        <div style={{
                            width: 250, border: '0px solid', boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.2)', borderRadius: '5px',
                            display: 'flex', flexDirection: 'column', gap: 10, padding: 10
                        }}>
                            <Button style={{ width: '100%' }}
                                danger={onLabel}
                                size="large" onClick={handleGoLabel} icon={onLabel ? <LeftSquareOutlined /> : <PlusSquareOutlined />}>
                                Synthetic Range
                            </Button>
                            <Button style={{ width: '100%' }}
                                danger={onCircle}
                                size="large" onClick={handleGoCircle} icon={onCircle ? <LeftSquareOutlined /> : <AimOutlined />}>
                                Cluster Range
                            </Button>

                            <Button style={{ width: '100%' }} size="large" danger={onEdit} onClick={() => handleEdit()} icon={<EditOutlined />}>
                                Edit
                            </Button>
                            {/* <Button style={{ width: '100%' }} size="large" onClick={() => handleDelete()} icon={<DeleteOutlined />}>
                                    Delete
                                </Button> */}
                            <Button style={{ width: '100%' }} size="large" onClick={() => handleClear()} icon={<UndoOutlined />}>
                                Clear
                            </Button>
                            <Button style={{ width: '100%' }} size="large" onClick={() => setTempForm(initFormData)} >
                                Reset
                            </Button>

                            <Divider />

                        </div>
                        {canvasImage &&
                            <LabelSyntheticCanvas
                                canvasSource={canvasImage}
                                annotations={annotations}
                                updateAnnotations={(newAnnotation) => setAnnotations(newAnnotation)}
                                onLabel={onLabel}
                                onCircle={onCircle}
                                onEdit={onEdit}
                                height={container[1]}
                                width={container[0]}
                                statusColor={rectColor}
                                onStageClick={handleOffAction}
                            //getCurrentRect={(id) => setCurrentRect(id)}
                            />}
                        <div>
                            <div style={{
                                width: 350, border: '0px solid', height: '100%',
                                boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.2)', borderRadius: '5px',
                                display: 'flex', flexDirection: 'column', gap: 10, padding: 10
                            }}>
                                <LabelSyntheticForm
                                    onSubmit={onSubmit}
                                    datasetId={datasetId}
                                    confirmEnabled={confirmEnabled}
                                    onCluster={onCluster}
                                    tempForm={tempForm}
                                />
                            </div>
                        </div>
                    </div>}
            </Modal>
        </>

    )
}

export default LabelSyntheticModal; 