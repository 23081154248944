import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  HashRouter,
} from "react-router-dom";
import AuthPage from "./pages/Auth";
import { useTypedSelector } from "./hooks/useTypedSelector";
import Layout from "./UI/Layout";
import OfflineHome from "./pages/Offline-Home/index";
import { useActions } from "./hooks/useActions";
import { useEffect } from 'react';

export interface AppProps {}

const App: React.FC<AppProps> = () => {
  
  const { currentUser } = useTypedSelector((state) => state.auth);

  const { refreshToken, loadConfig } = useActions();

  useEffect(() => {
    loadConfig();
    refreshToken();

    const interval = setInterval(async () => {
      // Refresh token logic here
      await refreshToken();
    }, 5 * 60 * 1000); // 5 minute

    return () => clearInterval(interval); 
  }, []); 

  useEffect(() => {
    loadConfig();
  }, [loadConfig,currentUser]); 


  if (!currentUser) {
    return (
      <Router>
        <Switch>
          <Route path="/auth">
            <AuthPage />
          </Route>
          <Route path="/">
            <Redirect to="/auth" />
          </Route>
        </Switch>
      </Router>
    );
  }
  return (
    <HashRouter>
      <Switch>
        <Layout>
          <Route path="/home">
            <OfflineHome />
          </Route>
          <Route path="/online-predict">
            <h1>hello2</h1>
          </Route>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
        </Layout>
      </Switch>
    </HashRouter>
  );
};

export default App;
