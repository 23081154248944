import * as _ from "lodash";
import { useEffect } from "react";
import { Moment } from "moment";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Card,
  Spin,
  message,
  Modal
} from "antd";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import styled from "@emotion/styled";
import { useActions } from "../../hooks/useActions";

import axios from "axios";
import { offlineUrlExpress } from "../../urls";

import { DownOutlined, MinusCircleOutlined, PlusOutlined, FilterOutlined, ClearOutlined  } from '@ant-design/icons';

const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

export interface ImageSelectFormProps { }

const ImageSelectForm: React.FC<ImageSelectFormProps> = () => {
  const [form] = Form.useForm();
  const {
    sourceFolder,
    isSourceFolderPending,
    isSourceImagesQueryPending,
    error,
    success,
  } = useTypedSelector((state) => state.insertImages);
  useEffect(() => {
    if (success) {
      form.resetFields();
    }
  }, [success, form]);

  useEffect(() => {
    if (error.length) {
      _.forEach(error, (err) => message.error(err, 3));
    }
  }, [error]);

  //const { addImagesToGroupAsync,addImagesToGroupFilterAsync,fetchSourceFoldersFilterAsync } = useActions();
  const { addImagesToGroupFilterAsync,fetchSourceFoldersFilterAsync } = useActions();
  const handleOnFinish = async (values: {
    sourceFolderIndex: number;
    filter: Array<string>;
    minDate: Moment;
    maxDate: Moment;
  }) => {
    /*
    const { sourceFolderIndex, search, minDate, maxDate } = values;
    const targetFolderId = sourceFolder[sourceFolderIndex].id;
    addImagesToGroupAsync(targetFolderId, search, minDate, maxDate);
    */
    const { sourceFolderIndex, filter, minDate, maxDate } = values;
    const targetFolderId = sourceFolder[sourceFolderIndex].id;
    addImagesToGroupFilterAsync(targetFolderId, filter, minDate, maxDate)

  };

  const handlePreview = async () => {

    let values: any = form.getFieldsValue();

    console.log(values);
    if (values.sourceFolderIndex !== undefined) {

      let targetFolderId = sourceFolder[values['sourceFolderIndex']].id;
      let postData = values;
      //delete postData.sourceFolderIndex;
      console.log(postData);


      axios.post(`${offlineUrlExpress}/api/dataset-add-image-preview/${targetFolderId}`, JSON.stringify(postData), { headers: { 'Content-Type': 'application/json' } })
        .then(res => {
          let previewlist: any = res.data;
          let previewstring = '';
          for (let i = 0; i < previewlist.length; i++) {
            previewstring += previewlist[i]['filename'] + '\n';
          }

          Modal.info({
            title: `Filter Preview (Total File Count : ${previewlist.length})`,
            content: (
              <><TextArea rows={20} value={previewstring}>

              </TextArea>
              </>
            ),
            width: 1000,
            onOk() { },
          });

        })
    }
  }

  const handleUpdateFolder = async () => {

    let values: any = form.getFieldsValue();
    console.log(values);

    fetchSourceFoldersFilterAsync(values.minFolderDate,values.maxFolderDate);

    form.setFieldsValue({
      sourceFolderIndex: undefined,
      minDate: values.minFolderDate,
      maxDate: values.maxFolderDate
    });
  }  

  const handleClearUpdateFolder = async () => {

    form.setFieldsValue({
      sourceFolderIndex: undefined,
      minFolderDate: null,
      maxFolderDate: null
    });
    
    let values: any = form.getFieldsValue();

    fetchSourceFoldersFilterAsync(values.minFolderDate,values.maxFolderDate);
  } 

  return (
    <Card style={{ marginTop: "1rem" }}>
      <Spin spinning={isSourceFolderPending || isSourceImagesQueryPending}>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
          layout="horizontal"
          onFinish={handleOnFinish}
        >

<Form.Item label="Folder Min Date" name="minFolderDate">
            <DatePicker showTime />
          </Form.Item>
          <Form.Item label="Folder Max Date" style={{ margin: 0 }} name="maxFolderDate">
            <DatePicker showTime />
          </Form.Item>
          <br/>
          <Form.Item>
            <Button type="dashed"
                    onClick={() => handleUpdateFolder()}
                    //style={{ width: '60%' }}
                    icon={<FilterOutlined />}>Filter Folder</Button>
            <Button type="dashed"
                    onClick={() => handleClearUpdateFolder()}
                    //style={{ width: '60%' }}
                    icon={<ClearOutlined />}>Clear Filter Folder</Button>                    
          </Form.Item>

          <Form.Item
            label="Select"
            name="sourceFolderIndex"
            rules={[{ required: true, message: "must have one source folder" }]}
          >
            <Select
              showSearch
              //optionFilterProp="children"
              filterOption={(input, option: any) => {
                //console.log(input,option.children[0]);
                return option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                //console.log(input,option.children[0]);
                //return true;
                //return option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                //return true;
                //option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              }
            >
              {sourceFolder.map((f, i) => (
                <Select.Option key={f.id} value={i}>
                  {/*{"/"+ f.origin_folder.split("/").slice(3, f.origin_folder.length - 2).join("/")} ({f.count},{f.origin_folder.split("/").slice(0,3).join("/")})*/}
                  {f.origin_folder} (Count : {f.count})
                </Select.Option>
              ))}
            </Select>



          </Form.Item>



          {/*
          <Form.Item label="Search" name="search">
            <Input />
          </Form.Item>
          */}

          <Form.List
            name="filter"
          /*
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 2) {
                  return Promise.reject(new Error('At least 2 passengers'));
                }
              },
            },
          ]}
          */
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Search Keyword' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input search keyword or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="Search Keyword" style={{ width: '60%' }} />
                    </Form.Item>
                    <MinusCircleOutlined
                      style={{ fontSize: "1.5rem", marginLeft: "5px" }}
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                  >
                    Add Keyword Filter
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item label="File Min Date" name="minDate">
            <DatePicker showTime />
          </Form.Item>
          <Form.Item label="File Max Date" style={{ margin: 0 }} name="maxDate">
            <DatePicker showTime />
          </Form.Item>

          <Form.Item style={{ margin: 0 }} wrapperCol={{ offset: 20, span: 2 }}>
            <Button style={{ color: "blue" }} type="link" onClick={handlePreview}>
              preview
            </Button>
            <Button style={{ color: "blue" }} type="link" htmlType="submit">
              add
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Card>
  );
};

export default ImageSelectForm;
