const origin = window.location.hostname;
// const origin = "192.168.2.20";
// const dash = "http://" + window.location.hostname + ":5050";
const online = window.location.origin;

export const onlineUrl = online + "/api";
//Function Remark
//export const offlineUrl = "http://" + origin + ":5000/api";
export const offlineUrl = onlineUrl;
export const offlineUrlExpress = online;
