import { Button, Checkbox, Form, InputNumber, message, Popover, Select, Slider, Switch } from 'antd';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { offlineUrlExpress } from '../../urls';

export interface FormDataType {
    modelId: string;
    class: string,
    generationImageCount: 10,
    generationObjectCountMinRange: number;
    generationObjectCountMaxRange: number;
    generationObjectSizeMin: number;
    generationObjectSizeMax: number;
    generationObjectAlphaMin: number;
    generationObjectAlphaMax: number;
    generationObjectRotationMin: number;
    generationObjectRotationMax: number;
    clusterRatio: number,
    category: string[]
}

export const initFormData: FormDataType = {
    modelId: "",
    class: "",
    generationImageCount: 10,
    generationObjectCountMinRange: 1,
    generationObjectCountMaxRange: 5,
    generationObjectSizeMin: 0.5,
    generationObjectSizeMax: 1.5,
    generationObjectAlphaMin: 0.1,
    generationObjectAlphaMax: 1,
    generationObjectRotationMin: 0,
    generationObjectRotationMax: 360,
    clusterRatio: 0.8,
    category: ['All']
};

export interface GenerationOptionType {
    all: boolean,
    countRange: boolean,
    sizeRange: boolean,
    alphaRange: boolean,
    rotationRange: boolean,
    clusterRatio: boolean
}

const GenerationOption: GenerationOptionType = {
    all: false,
    countRange: false,
    sizeRange: false,
    alphaRange: false,
    rotationRange: false,
    clusterRatio: false
};

export interface CategorySetting {
    label: string;
    classes: string;
    checked: boolean
}



const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};

const LabelSyntheticForm = ({
    datasetId, onSubmit, confirmEnabled, onCluster, tempForm
}: {
    datasetId: number,
    onSubmit: (data: FormDataType, classes: CategorySetting[], option: GenerationOptionType) => void,
    confirmEnabled: boolean,
    onCluster: boolean,
    tempForm?: FormDataType | null
}) => {
    const [form] = Form.useForm<FormDataType>();

    const [currentModel, setCurrentModel] = useState('');
    const [modelOptions, setModelOptions] = useState<any[]>([]);

    const [classes, setClasses] = useState<string[]>([]);
    const [generationOption, setGenerationOption] = useState(GenerationOption);

    const [categories, setCategories] = useState<CategorySetting[]>([]);

    const categoryRef = useRef<HTMLButtonElement | null>(null);

    const handleRefreshClassSummary = async () => {
        try {
            let get_class_url = `${offlineUrlExpress}/api/getdatasetclassessummary/${datasetId}`;
            const res = await axios.get(get_class_url);
            const classData = await res.data;
            const classString = classData.map((cls: any) => cls.classlabel);
            setClasses([...classString]);
            form.setFieldValue('class', 'None');
        } catch (err: any) {
            console.log(err.message)
        }
    };

    const getModeListAsync = async () => {
        try {
            const res = await fetch(`${offlineUrlExpress}/api/disg/getmodellist`);
            const data = await res.json();
            setModelOptions([...data]);
            const first = [...data][0].modelId as string;
            form.setFieldValue("modelId", first);
            setCurrentModel(first)
        } catch (err: any) {
            message.error(err.message)
        };
    };

    useEffect(() => {
        if (currentModel) {
            const data = modelOptions.find(item => item.modelId === currentModel);
            const dataset = data.category.map((cate: string) => {
                return {
                    label: cate,
                    classes: 'None',
                    checked: false
                }
            })
            setCategories(dataset)
        } else {
            setCategories([])
        }
    }, [currentModel, modelOptions]);

    useEffect(() => {
        getModeListAsync();
        handleRefreshClassSummary();
        if (!tempForm) {
            form.setFieldsValue(initFormData)
        } else {
            form.setFieldsValue(tempForm)
        }
    }, [tempForm]);

    const handleSwitch = (checked: boolean, key: string) => {
        if (key === 'all') {
            return setGenerationOption({
                all: checked,
                countRange: checked,
                sizeRange: checked,
                alphaRange: checked,
                rotationRange: checked,
                clusterRatio: checked,
            })
        } else {
            return setGenerationOption({
                ...generationOption,
                [key]: checked
            })
        };

    };


    const handleSelectCategory = (value: string[]) => {
        if (value.includes('All')) {
            form.setFieldValue('category', ['All'])
        } else {
            form.setFieldValue('category', value)
        }
    };


    const handleUpdateCategory = (item: CategorySetting) => {
        const updatedCategories = [...categories];
        const index = updatedCategories.findIndex(cate => cate.label === item.label);
        updatedCategories.splice(index, 1, item)
        setCategories(updatedCategories);
    };

    const handleCategoryCheck = (cate: string, check: boolean) => {
        const updatedCategories = [...categories];
        if (cate === 'All') {
            setCategories(updatedCategories.map(item => {
                return {
                    ...item,
                    checked: check
                }
            }));
        } else {
            const index = updatedCategories.findIndex(item => item.label === cate);
            const target = updatedCategories[index];
            handleUpdateCategory({ ...target, checked: check })
        }
    };

    const handleClassesChange = (cate: string, classes: string) => {
        const updatedCategories = [...categories];
        if (cate === 'All') {
            setCategories(updatedCategories.map(item => {
                return {
                    ...item,
                    classes: classes
                }
            }));
        } else {
            const index = updatedCategories.findIndex(item => item.label === cate);
            const target = updatedCategories[index];
            handleUpdateCategory({ ...target, classes: classes });
        }
    };

    return (
        <Form form={form} size="small" onFinish={(value) => onSubmit(value, categories, generationOption)} autoComplete="off" {...layout} >

            <Form.Item name="modelId" label="Model" rules={[{ required: true, message: 'Please select model !' }]}
                wrapperCol={{ offset: 4, span: 16 }}>

                <Select
                    size='middle'
                    style={{ width: '100%' }}
                    onChange={(value) => {
                        form.setFieldsValue({ "modelId": value, 'category': ['All'] });
                        setCurrentModel(value);
                        if (categoryRef && categoryRef.current) {
                            categoryRef.current.click();
                        }

                    }}

                    options={[...modelOptions].map(opt => {
                        return {
                            value: opt.modelId,
                            label: opt.modelName
                        }
                    })}
                />
            </Form.Item>

            <Popover placement="bottom" title={'Category'} content={<div>
                {categories.map(cate =>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: 300, gap: 10, padding: 2 }}>
                        <Checkbox
                            checked={cate.checked}
                            onChange={(e) => handleCategoryCheck(cate.label, e.target.checked)}
                        />
                        <span>{cate.label}</span>
                        <Select
                            disabled={cate.label === 'All' && !cate.checked}
                            size='small'
                            onChange={(value) => handleClassesChange(cate.label, value)}
                            value={cate.classes || 'None'}
                            style={{ width: 150 }}
                            options={
                                ['None', ...classes].map(opt => {
                                    return {
                                        value: opt,
                                        label: opt
                                    }
                                })
                            }
                        />
                    </div>
                )}
            </div>} trigger="click" showArrow={false}>
                <Button ref={categoryRef} size='middle' style={{ width: '100%', marginBottom: 16 }}>Category Setting *</Button>
            </Popover>


            {/* <Form.Item name="category" label="Category" rules={[{ required: true, message: 'Please select category !' }]}
                wrapperCol={{ offset: 4, span: 16 }}>
                <Select
                    size='middle'
                    style={{ width: '100%' }}
                    mode="multiple"
                    onChange={(value) => handleSelectCategory(value)}
                    options={categoryOption.map(cat => {
                        return {
                            value: cat,
                            label: cat
                        }
                    })}
                />
            </Form.Item> */}
            {/* <Form.Item name="class" label="Class" rules={[{ required: true, message: 'Please select Class!' }]}
                wrapperCol={{ offset: 4, span: 16 }}>
                <Select
                    size='middle'
                    style={{ width: '100%' }}
                    options={
                        ['None', ...classes].map(opt => {
                            return {
                                value: opt,
                                label: opt
                            }
                        })
                    }
                />
            </Form.Item> */}
            <Form.Item name="generationImageCount" label="Generation Image Count"
                labelCol={{ span: 0 }} wrapperCol={{ offset: 2, span: 16 }}>

                <InputNumber<number>
                    size='middle'
                    min={1}
                    style={{ width: '100%' }}

                    stringMode
                    onChange={(value) => form.setFieldValue('generationImageCount', value as number)}
                />
            </Form.Item>

            <p>
                Generation Object Count Range
            </p>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                <Form.Item name="generationObjectCountMinRange" label="Min"
                    labelCol={{ span: 0 }} wrapperCol={{ offset: 2, span: 6 }}>
                    <InputNumber<number>
                        //disabled={!generationOption.countRange}
                        size='small'
                        min={1}
                        max={100}
                        stringMode
                        onChange={(value) => form.setFieldValue('generationObjectCountMinRange', value as number)}
                    />
                </Form.Item>
                <Form.Item name="generationObjectCountMaxRange" label="Max"
                    labelCol={{ span: 0 }} wrapperCol={{ offset: 2, span: 6 }}>
                    <InputNumber<number>
                        //disabled={!generationOption.countRange}
                        size='small'
                        min={1}
                        max={100}
                        stringMode
                        onChange={(value) => form.setFieldValue('generationObjectCountMaxRange', value as number)}
                    />
                </Form.Item>
            </div>
            <p>
                <Switch size="small" onChange={(checked) => handleSwitch(checked, 'all')}
                    checked={generationOption.all} style={{ marginRight: 10 }} />
                All
            </p>
            <p>
                <Switch size="small" onChange={(checked) => handleSwitch(checked, 'sizeRange')}
                    checked={generationOption.sizeRange} style={{ marginRight: 10 }} />
                Generation Object Size Range
            </p>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Form.Item name="generationObjectSizeMin" label="Min"
                    labelCol={{ span: 0 }} wrapperCol={{ offset: 2, span: 6 }} >
                    <InputNumber<number>
                        disabled={!generationOption.sizeRange}
                        size='small'
                        min={0.01}
                        max={10}
                        step={0.01}
                        onChange={(value) => form.setFieldValue('generationObjectSizeMin', value as number)}
                    />
                </Form.Item>
                <Form.Item name="generationObjectSizeMax" label="Max"
                    labelCol={{ span: 0 }} wrapperCol={{ offset: 2, span: 6 }}>
                    <InputNumber<number>
                        disabled={!generationOption.sizeRange}
                        size='small'
                        step={0.01}
                        min={0.01}
                        max={10}
                        onChange={(value) => form.setFieldValue('generationObjectSizeMax', value as number)}
                    />
                </Form.Item>
            </div>
            <p>
                <Switch size="small" onChange={(checked) => handleSwitch(checked, 'alphaRange')}
                    checked={generationOption.alphaRange} style={{ marginRight: 10 }} />
                Generation Object Alpha Range</p>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Form.Item name="generationObjectAlphaMin" label="Min"
                    labelCol={{ span: 0 }} wrapperCol={{ offset: 2, span: 6 }}>
                    <InputNumber<number>
                        disabled={!generationOption.alphaRange}
                        size='small'
                        min={0.1}
                        max={1}
                        step={0.1}
                        onChange={(value) => form.setFieldValue('generationObjectAlphaMin', value as number)}
                    />
                </Form.Item>

                <Form.Item name="generationObjectAlphaMax" label="Max"
                    labelCol={{ span: 0 }} wrapperCol={{ offset: 2, span: 6 }}>
                    <InputNumber<number>
                        disabled={!generationOption.alphaRange}
                        size='small'
                        min={0.1}
                        max={1}
                        step={0.1}
                        onChange={(value) => form.setFieldValue('generationObjectAlphaMax', value as number)}
                    />
                </Form.Item>
            </div>
            <p>
                <Switch size="small" onChange={(checked) => handleSwitch(checked, 'rotationRange')}
                    checked={generationOption.rotationRange} style={{ marginRight: 10 }} />
                Generation Object Rotation Range</p>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Form.Item name="generationObjectRotationMin" label="Min"
                    labelCol={{ span: 0 }} wrapperCol={{ offset: 2, span: 6 }}>
                    <InputNumber<number>
                        disabled={!generationOption.rotationRange}
                        size='small'
                        min={0}
                        max={360}
                        onChange={(value) => form.setFieldValue('generationObjectRotationMin', value as number)}
                    />
                </Form.Item>
                <Form.Item name="generationObjectRotationMax" label="Max"
                    labelCol={{ span: 0 }} wrapperCol={{ offset: 2, span: 6 }}>
                    <InputNumber<number>
                        disabled={!generationOption.rotationRange}
                        size='small'
                        min={0}
                        max={360}
                        onChange={(value) => form.setFieldValue('generationObjectRotationMax', value as number)}
                    />
                </Form.Item>
            </div>
            <p>
                <Switch size="small" onChange={(checked) => handleSwitch(checked, 'clusterRatio')}
                    checked={generationOption.clusterRatio} style={{ marginRight: 10 }} disabled={!onCluster} />
                {`Cluster Ratio  [ Sparsity <--> Density ]`}
            </p>
            <Form.Item name="clusterRatio" label=""
                labelCol={{ span: 24 }} wrapperCol={{ offset: 2, span: 20 }}>
                <Slider
                    min={0}
                    max={1.0}
                    step={0.01}
                    style={{ width: '100%' }}
                    onChange={(value) => form.setFieldValue('clusterRatio', value)}
                />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                <Button size="large" type='primary' htmlType="submit" disabled={!confirmEnabled}>
                    Confirm Generation
                </Button>

            </Form.Item>

        </Form>
    )
}

export default LabelSyntheticForm