
import { KonvaEventObject } from "konva/lib/Node";
import { useState } from "react";
import { Circle, Group, Line } from "react-konva";
import { AnchorType, Shape } from "../../contexts/DrawerTypes";
import { hexToRgba } from "../../contexts/DrawerUtils";

const PainterPolygon = ({
    shape,
    rectColor,
    fill,
    points,
    onSelected,
    onCompleted,
    onContext

}: {
    shape: Shape,
    rectColor: string,
    fill: boolean,
    points: AnchorType[]
    onSelected?: () => void;
    onCompleted?: () => void;
    onContext?: (e: KonvaEventObject<PointerEvent>) => void,
}) => {
    const { isComplete, id, visible } = shape;

    const [circleRect, setCircleRect] = useState({
        strokeWidth: 2,
        fill: "transparent"
    });

    const handleAnchorContext = (e: KonvaEventObject<PointerEvent>) => {
        e.evt.preventDefault()
        return onContext && onContext(e)
    };


    return (
        <Group visible={visible}>
            <Line
                id={id}
                stroke={rectColor}
                fill={fill ? hexToRgba(rectColor) : "transparent"}
                strokeWidth={3}
                scaleStrokeEnabled={false}
                onClick={onSelected}
                onContextMenu={handleAnchorContext}

                closed={isComplete}
                points={points.flatMap((point) => [point.x, point.y])}
            />

            {!isComplete && points.map((point, index) =>
                <Circle
                    key={id + index * 3}
                    id={point.index.toString()}
                    x={point.x}
                    y={point.y}
                    stroke="#eee"
                    radius={5}
                    fill={rectColor}
                />
            )}
            {points[0] && !isComplete && onCompleted && (
                <Circle
                    key={id + '-origin-anchor'}
                    id='origin-anchor'
                    x={points[0].x}
                    y={points[0].y}
                    radius={10}
                    stroke={rectColor}
                    fill={circleRect.fill}
                    strokeWidth={circleRect.strokeWidth}
                    onMouseOver={() => {
                        document.body.style.cursor = "pointer";
                        if (points.length < 4) {
                            setCircleRect({
                                strokeWidth: 1,
                                fill: "red"
                            })
                        } else {
                            setCircleRect({
                                strokeWidth: 1,
                                fill: rectColor
                            });
                        }
                    }}
                    onMouseOut={() => {
                        document.body.style.cursor = "default";
                        setCircleRect({
                            strokeWidth: 2,
                            fill: "transparent"
                        });
                    }}
                    onClick={() => {
                        document.body.style.cursor = "default";
                        if (points.length > 2) {
                            return onCompleted()
                        }
                    }}
                />
            )}
        </Group>
    );
}

export default PainterPolygon