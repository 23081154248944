import { Button } from 'antd';
import { useMemo } from 'react';


export const classColorfully =
  ['#FF00FF', '#D291BC', '#FFA500', '#7FFFD4', '#00FFFF', '#473810', '#3B2F2F', '#7E3517', '#FF6347', '#B22222', '#CC7A8B', '#F778A1'];

const ClassColorPicker = ({ currentColors, onPickColor

}: {
  currentColors: string[],
  onPickColor: (color: string | undefined) => void
}) => {

  const displayColor = useMemo(() => {
    return classColorfully.filter(color => !currentColors.includes(color));
  }, [currentColors]);


  return (
    <div style={{ display: 'flex', gap: 10 }}>
      <Button size='small' onClick={() => onPickColor(undefined)}>
        None
      </Button>
      {displayColor.map(color =>
        <Button key={color} size='small' shape="circle" onClick={() => onPickColor(color)}>
          <div style={{ width: 14, height: 14, borderRadius: 7, background: color, margin: 'auto' }} />
        </Button>
      )}
    </div>
  )
}

export default ClassColorPicker