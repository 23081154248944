import { BulbTwoTone, ExpandOutlined, MinusCircleFilled, RedoOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Checkbox, InputNumber, Radio, Slider } from 'antd';
import { CanvasOptionType, colorType } from ".";
import { useCanvas } from "../../contexts/DrawerContext";

const TopPanel = ({
  canvasOption,
  onSetCanvasOption,
  onScale,
  resetScale
}: {
  canvasOption: CanvasOptionType,
  onSetCanvasOption: (key: string, value: string | boolean | number) => void
  onScale: (value: number) => void
  resetScale: () => void
}) => {

  const { canvasState } = useCanvas();

  const { undo, redo, } = useCanvas();

  return (
    <div style={{ height: 34, border: '1px solid #ccc', width: '98%', margin: 'auto', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>

      <Radio.Group size='small'>
        <Radio.Button value="start">
          <RedoOutlined onClick={() => redo()} />
        </Radio.Button>
        <Radio.Button value="end">
          <UndoOutlined onClick={() => undo()} />
        </Radio.Button>
      </Radio.Group>
      <div style={{ display: 'flex', gap: 5, alignItems: 'center', }}>
        <h3 style={{ paddingTop: 5 }}>Line color</h3>
        {Object.entries(colorType).map(([key, value]) =>
          <Button
            onClick={() => onSetCanvasOption('rectColor', value)}
            key={key} shape="circle" size='small' icon={<MinusCircleFilled style={{ color: value }} />} />
        )}
      </div>
      <div>
        <Checkbox
          checked={canvasOption.fill}
          onChange={(e) => onSetCanvasOption('fill', e.target.checked)}>Fill</Checkbox>
      </div>
      <div style={{ display: canvasState.datasetType === 'KEYPOINT' ? 'block' : 'none' }}>
        Point R <InputNumber
          size='small'
          max={12} min={3}
          style={{ width: 60 }} value={canvasOption.radius}
          onChange={(value) => onSetCanvasOption('radius', value!)}
        />
      </div>
      <div style={{ display: 'flex', gap: 12 }}>
        <BulbTwoTone />
        <Slider
          reverse
          style={{ width: 200 }}
          min={-1}
          max={0}
          step={0.05}
          tooltip={{ open: false }}
          onChange={(value) => {
            onSetCanvasOption('brightness', value)
          }}
          value={canvasOption.brightness}
        />
      </div>
      <div style={{ display: 'flex', gap: 12, }}>
        <h3 style={{ paddingTop: 5 }}>Reset</h3>
        <ExpandOutlined onClick={() => resetScale()} />
      </div>
    </div>
  )
}

export default TopPanel