import "antd/dist/antd.css";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./states";

import App from "./app";

import { DrawerProvider } from "./contexts/DrawerContext";





ReactDOM.render(
  <Provider store={store}>
    <DrawerProvider >

      <App />

    </DrawerProvider>
  </Provider>,
  document.getElementById("root")
);
