import styled from '@emotion/styled';
import { Divider } from 'antd';
import { CanvasStateType } from '../../contexts/DrawerTypes';



const InfoSection = styled.div`  
  padding: 2px;
  text-align: center;
  h3 {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 10px;
  }

`;


const InfoP = styled.p`
    margin:auto;
    width:80%;
  word-wrap: break-word;
  border:1px solid rgb(222,222,222);
  border-radius:5px;

`;

const Information = ({ state }: { state: CanvasStateType }) => {
    const { datasetId, datasetName, datasetType, currentImage } = state;
    return (
        <div style={{ width: 320 }}>
            <InfoSection>
                <h3>Current Url:</h3>
                <p style={{ wordWrap: 'break-word' }}>{currentImage?.url}</p>
                <Divider />
            </InfoSection>
            <InfoSection>
                <h3>Dataset Type:</h3>
                <InfoP>{datasetType}</InfoP>
                <Divider />
            </InfoSection>
            <InfoSection>
                <h3>Dataset Name:</h3>
                <InfoP>{datasetName}</InfoP>
                <Divider />
            </InfoSection>
            <InfoSection>
                <h3>Image Name:</h3>
                <InfoP>{currentImage?.filename}</InfoP>
                <Divider />
            </InfoSection>
            <InfoSection>
                <h3>Image Source:</h3>
                <p style={{ wordWrap: 'break-word' }}>{currentImage?.sourceOrigin}</p>
                <Divider />
            </InfoSection>


        </div>
    )
}

export default Information