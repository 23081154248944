import { Button, Modal, Spin } from 'antd';
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { LabelElement } from "../../states/reducers/onGoinglabel";

import { offlineUrlExpress } from "../../urls/index";

function roundUptodecimal3(toRound: number) {
  return parseFloat(toRound.toFixed(6));
}

const resizeCoordinate = (
  x: number,
  y: number,
  position: string,
  coordinates: {
    topLeftX: number;
    topLeftY: number;
    bottomRightX: number;
    bottomRightY: number;
  }
) => {
  const { topLeftX, topLeftY, bottomRightX, bottomRightY } = coordinates;
  let newX1 = topLeftX;
  let newY1 = topLeftY;
  let newX2 = bottomRightX;
  let newY2 = bottomRightY;
  if (position === "tl" || position === "start") {
    newX1 = x;
    newY1 = y;
  }
  if (position === "br" || position === "end") {
    newX2 = x;
    newY2 = y;
  }
  if (position === "tr") {
    newX2 = x;
    newY1 = y;
  }
  if (position === "bl") {
    newX1 = x;
    newY2 = y;
  }
  return {
    topLeftX: newX1,
    topLeftY: newY1,
    bottomRightX: newX2,
    bottomRightY: newY2,
  };
};

const nearPoint = (
  x: number,
  y: number,
  x1: number,
  y1: number,
  name: string,
  clientWidth: number,
  clientHeight: number
) => {
  return Math.abs((x1 - x) * clientWidth) < 5 &&
    Math.abs((y1 - y) * clientHeight) < 5
    ? name
    : undefined;
};
function isWithinElement(
  x: number,
  y: number,
  element: LabelElement,
  imageRef: HTMLImageElement
) {
  const {
    topLeftX: x1,
    topLeftY: y1,
    bottomRightX: x2,
    bottomRightY: y2,
  } = element;
  const { clientWidth, clientHeight } = imageRef;
  // const x1 = topLeftX * clientWidth + clientX;
  // const x2 = bottomRightX * clientWidth + clientX;
  // const y1 = topLeftY * clientHeight + clientY;
  // const y2 = bottomRightY * clientHeight + clientY;
  const topLeft = nearPoint(x, y, x1, y1, "tl", clientWidth, clientHeight);
  const topRight = nearPoint(x, y, x2, y1, "tr", clientWidth, clientHeight);
  const bottomLeft = nearPoint(x, y, x1, y2, "bl", clientWidth, clientHeight);
  const bottomRight = nearPoint(x, y, x2, y2, "br", clientWidth, clientHeight);

  const inside = x > x1 && x < x2 && y > y1 && y < y2 ? "inside" : undefined;

  return topLeft || inside || topRight || bottomLeft || bottomRight;
}
const getElementPosition = (
  x: number,
  y: number,
  elements: LabelElement[],
  imageRef: HTMLImageElement
) => {
  return elements
    .map((element) => ({
      ...element,
      position: isWithinElement(x, y, element, imageRef),
    }))
    .find((element) => element.position !== undefined);
};

const cursorForPosition = (postion: string) => {
  switch (postion) {
    case "tl":
    case "br":
    case "start":
    case "end":
      return "nwse-resize";
    case "tr":
    case "bl":
      return "nesw-resize";
    case "inside":
      return "grab";
    default:
      return "default";
  }
};
// api

export const createLabelElement = (
  id: number,
  topLeftX: number,
  topLeftY: number,
  bottomRightX: number,
  bottomRightY: number,
   classificationId?:number
): LabelElement => {
  return { id, topLeftX, topLeftY, bottomRightX, bottomRightY, classificationId };
  /*
  let areasize = ((bottomRightX-topLeftX)* (bottomRightY-topLeftY));
  console.log(`areasize : ${areasize}`);
  if (areasize > 0){
    return { id, topLeftX, topLeftY, bottomRightX, bottomRightY };
  } else {
    bottomRightX = bottomRightX + 0.05;
    bottomRightY = bottomRightY + 0.05;
    return { id, topLeftX, topLeftY, bottomRightX, bottomRightY};
  }*/
};
const adjustElementCoordinates = (element: LabelElement) => {
  const { topLeftX, topLeftY, bottomRightX, bottomRightY } = element;
  const minX = Math.min(topLeftX, bottomRightX);
  const maxX = Math.max(topLeftX, bottomRightX);
  const minY = Math.min(topLeftY, bottomRightY);
  const maxY = Math.max(topLeftY, bottomRightY);
  return {
    topLeftX: minX,
    topLeftY: minY,
    bottomRightX: maxX,
    bottomRightY: maxY,
  };
};
//



export interface LabelImageDetectProps {
  dispalyImageRef: HTMLImageElement;
  browsering: boolean;
  imageType: string;
  scalesImage:boolean;

  imgUrl: string;
}

const LabelImageDetect: React.FC<LabelImageDetectProps> = ({
  dispalyImageRef,
  browsering,
  imageType,
  scalesImage,

  imgUrl,
}) => {

  const { currentLabelElements, currentHoverElementIndex } = useTypedSelector(
    (state) => state.onGoinglabel
  );
    
  const {
    currentIndex,
    onGoingDatasetName,
    onGoingDatasetType,
    currentImage,
    onGoingClassifications,
    isLabelUpdating,
    autoLabeling
  } = useTypedSelector((state) => state.onGoinglabel);

  const { 
    setCurrentLabelElements,
    deleteLabelAll,
    deleteLabelByIndex,
    setCurrentHoverLabelElement,//hovor color
    setCurrentLabelClassification,
    setCurrentLabelId
  } = useActions();

  const [action, setAction] = useState("none");
  const [selectedElement, setSelectedElement] =
    useState<LabelElement | null>(null);
  const [coordinates,setCoordinates] =useState<[number,number]>([0,0]);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  
  const [drawEnd,setDrawEnd] = useState<boolean|undefined>(false);
  const [drawId, setDrawId] = useState<number | null>(null);//監聽繪圖box的id
  const [origin, setOrigin] =useState<{ox:number,oy:number,ow:number,oh:number}>({ox:0,oy:0,ow:0,oh:0}); 
  const [offsetMove,setOffsetMove] = useState<{moveX:number,moveY:number}>({moveX:0,moveY:0});
  const [loadImage, setLoadImage] = useState<boolean>(false);

  const getOffsetCoordinate=():[number,number]=>{
    //計算原點座標偏移距離
    let dx,dy;
    if (canvasRef.current) {
      const canvas = canvasRef.current
      const { x, y } = canvas.getBoundingClientRect();
      dx = x - coordinates[0];
      dy = y - coordinates[1];
      return [dx, dy];
    } else return [0, 0];
  };

  useEffect(() => {
    const { x, y, width: w, height: h } = dispalyImageRef.getBoundingClientRect();
    let origins = {
      ox: x,
      oy: y,
      ow: w,
      oh: h
    };
    setCoordinates([x,y]);
    setOrigin({ ...origins });
  },[dispalyImageRef])

  useEffect(() => {
    if ((imageType === "CLASSIFY" || imageType === "ABNORMAL")  && currentLabelElements.length <= 0) {
      setCurrentLabelElements([
        {
          id: new Date().getTime(),
          topLeftX: 0,
          topLeftY: 0,
          bottomRightX: 1,
          bottomRightY: 1,
        },
      ]);
    }
  }, [imageType, setCurrentLabelElements, currentLabelElements]);

  useEffect(() => {
    if (browsering) {
      setAction("none");
    };
  }, [browsering]);


  useLayoutEffect(() => {
    const img = new Image();
    img.onload = () => {
      if (canvasRef && canvasRef.current) {
        const canvas = canvasRef.current;
        const { width: w, height: h } = dispalyImageRef.getBoundingClientRect();
        const distance = getOffsetCoordinate();
        let [ dx, dy ] = distance;
  
        const context = canvas.getContext("2d");
        context!.clearRect(0, 0, w, h);
        context!.drawImage(img,0+dx,0+dy,w,h)
        context!.lineWidth = 3;
        currentLabelElements.forEach(
          ({ topLeftX, topLeftY, bottomRightX, bottomRightY,classificationId }, i) => {
            if (i === currentHoverElementIndex) {
              context!.strokeStyle = "yellow";
            }else if(classificationId){
              context!.strokeStyle = "#950026"
              // context!.setLineDash([3,1])
            }else {
              context!.strokeStyle = "blue";
            };
  
            return context!.strokeRect(
              (topLeftX * w) + dx,
              (topLeftY * h) + dy,
              (bottomRightX - topLeftX) * w,
              (bottomRightY - topLeftY) * h
            );
          }
        );
  
      };
    }
    img.src = imgUrl;
  });

  const checkInCanvas = (clientX:number,clientY:number) => {
      const { ox, oy, ow, oh } = origin;
      if (clientX < ox || clientY < oy || clientX > ow || clientY > oh) {
        console.log("hello")
      }
  }

  const updateElements = (
    id: number,
    x1: number,
    y1: number,
    clientX: number,
    clientY: number,
     classificationId?:number
  ) => {
    const newElement = createLabelElement(id, x1, y1, clientX, clientY, classificationId);
    const updatedElements = [...currentLabelElements];
    const targetIndex = updatedElements.findIndex((ele) => ele.id === id);
    updatedElements.splice(targetIndex, 1, newElement);
    return setCurrentLabelElements(updatedElements);
  };

  const windowToCanvas = (dispalyImageRef: HTMLImageElement, clientX: number, clientY: number): [number, number] => {
    const { ox, oy, ow, oh } = origin;
    const { x, y, width: w, height: h, } = dispalyImageRef.getBoundingClientRect();
    let scaleX = w / ow //w倍率
    let scaleY = h / oh //h倍率
    let mx = ox + ow / 2; //canvas x軸中點座標
    let my = oy + oh / 2; //canvas y軸中點座標
    let offsetX = ((mx - clientX) / ow - (mx - clientX - offsetMove.moveX) / w) * w / scaleX;//x點縮放後離中心偏移量
    let offsetY = ((my - clientY) / oh - (my - clientY - offsetMove.moveY) / h) * h / scaleY;//y點縮放後離中心偏移量
    let canvasX = (clientX - x) + offsetX;
    let canvasY = (clientY - y) + offsetY;
    return [canvasX,canvasY];
  };
  
  const handleOnMouseDown = (event: React.MouseEvent) => {
   
    if ((imageType === "CLASSIFY" || imageType === "ABNORMAL")||scalesImage) {
      return;
    }
  
    const {
      x,
      y,
      width: w,
      height: h,
    } = dispalyImageRef.getBoundingClientRect();
    
    const { clientX, clientY } = event;

    const [canvasX, canvasY] = windowToCanvas(dispalyImageRef, clientX, clientY);

    const newX = roundUptodecimal3(canvasX / w);//取得百分比
    const newY = roundUptodecimal3(canvasY / h);

    if (browsering) {
      
      const targetElement = getElementPosition(
        newX,
        newY,
        currentLabelElements,
        dispalyImageRef
      );
    
      if (targetElement) {
        setSelectedElement({
          ...targetElement,
          offsetX: newX - targetElement.topLeftX,
          offsetY: newY - targetElement.topLeftY,
        });
        if (targetElement.position !== "inside") {
          return setAction("resize");
        }

        return setAction("moving");
      }
    } else {
      setAction("drawing")
      const id = new Date().getTime();
      //we set the index as an id,because we are gonna create a new element so the index
      //equal to elements.length
      const element = createLabelElement(id, newX, newY, newX, newY);

      setCurrentLabelElements([...currentLabelElements, element]);
      setSelectedElement(element);
    }
  };
  //we create an element when it was click and start from where it start


  const handleOnMouseMove = (
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>
  ) => {
   
    const { clientX, clientY } = event;
    const { ox, oy, oh, ow } = origin;
    const {
      x,
      y,
      width: w,
      height: h,
    } = dispalyImageRef.getBoundingClientRect();
    const [canvasX, canvasY] = windowToCanvas(dispalyImageRef, clientX, clientY);
    const newX = roundUptodecimal3(canvasX / w);
    const newY = roundUptodecimal3(canvasY / h);
    let eventTarget = event.target as HTMLCanvasElement;
    //計算離原本的座標偏移
    if(scalesImage&&canvasRef.current){
        let mx = ox+ow/2,my = oy+oh/2;
        const {
          x,
          y,
          width: w,
          height: h,
        } = dispalyImageRef.getBoundingClientRect();
      let nowX = x + w / 2, nowY = y + h / 2;
     
        setOffsetMove({moveX:mx-nowX, moveY: my-nowY});
    }
    if (browsering&&!scalesImage) {
      
      const targetElement = getElementPosition(
        newX,
        newY,
        currentLabelElements,
        dispalyImageRef
      );

      //瀏覽時滑鼠移到有target時變成grab，無則default
      // eventTarget.style.cursor = targetElement
      // ? cursorForPosition(targetElement.position!)
      // : "default";
      if(targetElement){
        eventTarget.style.cursor = cursorForPosition(targetElement.position!)
        const { id,classificationId } = targetElement
       
        const targetIndex = currentLabelElements.findIndex(
          (ele) => ele.id === id
        );
     
        setCurrentLabelId(id)
        setCurrentHoverLabelElement(targetIndex);
      }else{
        eventTarget.style.cursor="default";
        setCurrentLabelId(null);
        setCurrentHoverLabelElement(-1);
      }
    }

    // const element = getElementPosition(clientX, clientY, elements);

    if (action === "moving" && selectedElement) {

      eventTarget.style.cursor = "grabbing";
      const {
        id,
        topLeftX,
        topLeftY,
        bottomRightX,
        bottomRightY,
        offsetX,
        offsetY,
         classificationId,
      } = selectedElement;

      const ox = newX - offsetX!;
      const oy = newY - offsetY!;
      const width = bottomRightX - topLeftX;
      const height = bottomRightY - topLeftY;
      const updateX = ox < 0 ? 0 : ox;
      const updateY = oy < 0 ? 0 : oy;
      const updateW = (ox + width) > 1 ? 1 : ox + width;
      const updateH = (oy + height) > 1 ? 1 : oy + height;
      
      return updateElements(
        id,
        updateX,
        updateY,
        updateW,
        updateH,
         classificationId
      );
    }
    if (action === "resize" && selectedElement) {
      const { id, position, classificationId, ...coordinates } = selectedElement;
     
      const { topLeftX, topLeftY, bottomRightX, bottomRightY } =
        resizeCoordinate(newX, newY, position!, coordinates);

      return updateElements(id, topLeftX, topLeftY, bottomRightX, bottomRightY, classificationId);
    }
    if (action === "drawing") {
      //when draw always the last one
      const index = currentLabelElements.length - 1;
      const { id, topLeftX, topLeftY } = currentLabelElements[index];
    
      if (clientX < ox || clientY < oy || clientX > ox + ow || clientY > oy + oh) {
        setAction("none")
        deleteLabelByIndex(index);
        return;
      };
      // we create a new Rough element and will replace the old one
      return updateElements(id, topLeftX, topLeftY, newX, newY);
    }
  };

  const setModelState =(index: number)=>{
    setDrawId(index)
    setCurrentHoverLabelElement(index);
    setDrawEnd(true);
  }

  const handleOnMouseUp = ( event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    
    if ((action === "drawing" || action === "resize") && selectedElement) {
      //we want to adjust the coordinate of the element , we wannna make
      //the rectangle always has smaller x,y in  x1 y1
      //the line always has veticall x,y in  x1 y1
      const { id } = selectedElement;
      const targetIndex = currentLabelElements.findIndex(
        (ele) => ele.id === id
      );
      const targetElement = currentLabelElements[targetIndex];
      // setCurrentHoverLabelElement(targetIndex);//hover element
      const { classificationId } = targetElement;
      const { topLeftX, topLeftY, bottomRightX, bottomRightY } =
        adjustElementCoordinates(targetElement);
      updateElements(id, topLeftX, topLeftY, bottomRightX, bottomRightY, classificationId);

      if(onGoingClassifications.length!==0 && action !== "resize"){
        setModelState(targetIndex);
      }

      // delete elements when area size = 0
      if (action === "drawing"){
        let areasize = ((bottomRightX-topLeftX)* (bottomRightY-topLeftY));
        //here to pop menu id
        if (areasize === 0 && selectedElement){
          deleteLabelByIndex(targetIndex);
          setDrawEnd(false);
        }
      }
    }
    //仿效 onClick function => 點擊後顯示
    if(selectedElement && action === "moving"){
      const { id, topLeftX:sx, topLeftY:sy} = selectedElement;
      const targetIndex = currentLabelElements.findIndex(
        (ele) => ele.id === id
      );
      const { topLeftX:nx, topLeftY:ny } = adjustElementCoordinates(currentLabelElements[targetIndex]);
      
      if((sx-nx)===0 && (sy-ny)===0){
        const { id } = selectedElement;
        const targetIndex = currentLabelElements.findIndex(
          (ele) => ele.id === id
        );
        setModelState(targetIndex);
      }
    }
    setAction("none");
    setSelectedElement(null); 
    
  };

  //modal turn off void 
  const closeElement=()=>{
    setDrawEnd(false)
    setCurrentHoverLabelElement(-1)
  }

  const [classId,setClassId] = useState<number>(-1);

  const modal =(index:number|null)=>{
    // const index = currentLabelElements.length-1;
    if(index!==null&&currentLabelElements[index]){
      const {classificationId} = currentLabelElements[index];
    return (
      <Modal
          title="Pick a class"
          centered
          style={{ top: 20 }}
          visible={drawEnd}
          // onOk={() => this.setModal1Visible(false)}
          onCancel={() =>closeElement()}
          width={200+30*onGoingClassifications.length}
          footer={[
            <Button key="deletel" onClick={()=>{deleteLabelByIndex(index);closeElement()}} danger>
              Delete
            </Button>,
            <Button key="cancel" onClick={()=>closeElement()}>
            Cancel
          </Button>
            ]}
        >
          {onGoingClassifications?.map(({ id, description },key) => (
            <Button
              danger={classificationId===id}
              ghost={key!==classId}
              type="primary"
              style={{margin:4}}
              key={id}
              onMouseEnter={()=>setClassId(key)}
              onMouseLeave={()=>setClassId(-1)}
              onClick={() => {
                setCurrentLabelClassification(index, id)
                closeElement()
              }}
            >
              {description}
            </Button>
          ))}
          
        </Modal>
    )
    }
  }


  return (
    <>
    <div
        style={{
        position:"absolute",
 
        // paddingLeft:"8rem",
        // top: dispalyImageRef.getBoundingClientRect().y*0,
        // left: dispalyImageRef.getBoundingClientRect().x*0-8*16,
        minWidth: dispalyImageRef.getBoundingClientRect().width+'px',
        minHeight: dispalyImageRef.getBoundingClientRect().height+'px',
        zIndex: 1000,
      }}
      >
        <Spin tip="Auto Labeling..." spinning={autoLabeling}>
      <canvas 
        width={origin.ow}
        height={origin.oh}
        ref={canvasRef}
        onMouseDown={handleOnMouseDown}
        onMouseMove={handleOnMouseMove}
        onMouseUp={handleOnMouseUp}
          style={{
            width: origin.ow+'px',
            height: origin.oh+'px',
        }}
          ></canvas>
          </Spin>
    </div>
    {modal(drawId)}
    </>
  );
};

export default LabelImageDetect;
//image middle page

