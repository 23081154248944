import styled from "@emotion/styled";

import { Checkbox, Dropdown, InputNumber, Menu, Modal, Popconfirm, Select, Slider, message } from "antd";
import Button from "antd-button-color";
import axios from "axios";
import React, { useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useStore } from "react-redux";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { LabelElement } from "../../states/reducers/onGoinglabel";
import { offlineUrlExpress } from "../../urls";
import LabelSyntheticModal from "../Label-Synthetic-Modal";
import { createLabelElement } from "../Label-image-DETECT";


const { Option } = Select;

const LabelTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;
const InfoSection = styled.div<{ extends?: boolean }>`
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  h3 {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  flex-grow: ${(props) => (props.extends ? 1 : 0)};
  margin-bottom: 0.5rem;
`;
const InfoP = styled.p`
  text-align: center;
  word-wrap: break-word;
`;

interface AutoLabelResultType {
  image_url: string;
  result: { bbox: number[], class: string, score: number }[]
};

interface ModelType {
  classes: any;
  create_at: string;
  lastupdate: string;
  modelid: string;
  modelname: string;
  modeltype: string
  params: any//{epochs: 10, model_backbone: 'resnet50', learning_rate: 0.0001, optimizer: 'adam', use_pretrained: 'true', …}
  parentmodelid: string | null;
  userId: number;

};

export interface LabelImageControlPannelProps {

}


const LabelImageControlPannel: React.FC<LabelImageControlPannelProps> = () => {

  const {
    currentIndex,
    onGoingDatasetName,
    onGoingDatasetType,
    currentImage,
    currentLabelElements,
    onGoingClassifications,
    isLabelUpdating,
    currentLabelId,
    classSummary,
    autoLabeling,
    onGoingDatasetId
  } = useTypedSelector((state) => state.onGoinglabel);


  const { currentUser, currentPermission } = useTypedSelector((state) => state.auth);
  
  const userPermission = JSON.parse(atob(currentPermission));

  const {autolabelFunction, autorunFunction, classifyFunction, segmentationFunction, keypointFunction, abnormalFunction, yoloFunction, angleFunction, smalldefectFunction, disgFunction} = userPermission;

  const store = useStore();

  const {
    setCurrentLabelElements,
    setCurrentLabelId,
    setCurrentHoverLabelElement, //popup class 選項
    deleteLabelAll,
    deleteLabelByIndex,
    insertDetectLabelsToImageByIdAsync,
    setCurrentLabelClassification,
    datasetImageNavigateTo,
    setAutoLabeling
  } = useActions();

  const onDeleteLabelConfirm = (index: number) => {
    deleteLabelByIndex(index);
  };

  const [openAuto, setOpenAuto] = useState<boolean>(false);
  const [models, setModels] = useState<ModelType[]>([]);
  const [selectModel, setSelectModel]
    = useState<ModelType | undefined>(undefined);
  // const onDeleteLabelCancel = () => {};
  const saveButtonRef: any = useRef(null);
  const removeAllLabelButtonRef: any = useRef(null);
  const autoLabelRef: any = useRef(null);
  const [scoreValue, setScoreValue] = useState(0.8);
  const [autoSave, setAutoSave] = useState<boolean>(false);
  const getCurrentIndex = () => {
    let gstate = store.getState();
    return gstate.onGoinglabel.currentIndex;
  }

  const getImagesCount = () => {
    let gstate = store.getState();
    return gstate.onGoinglabel.imagesCount;
  }

  const getIsOnGoingLabelModelOpen = () => {
    let gstate = store.getState();
    return gstate.onGoinglabel.isOnGoingLabelModelOpen;
  }

  /*
  useHotkeys('z', ():any => {
    console.log("Test Hotkey");
    console.log(store.getState());
    
    //saveButtonRef.current.click();
  })

  useHotkeys('c', ():any => {
    console.log("Test Hotkey");
    console.log(getCurrentIndex());
    datasetImageNavigateTo(getCurrentIndex() + 1);
    //saveButtonRef.current.click();
  })
*/


  useHotkeys('s', (): any => {
    if (getIsOnGoingLabelModelOpen()) {
      console.log("Save Hotkey");
      saveButtonRef.current.click();
    }
  });

  useHotkeys('l', (): any => {
    if (getIsOnGoingLabelModelOpen()) {
      console.log("auto Hotkey");
      autoLabelRef.current.click();
    }
  });

  useHotkeys('w', (): any => {
    if (getIsOnGoingLabelModelOpen()) {
      // console.log(currentLabelElements);
      // console.log(onGoingClassifications);
      deleteLabelAll();
    }
    /*
    for (let i=0; i < currentLabelElements.length; i++){
      console.log(currentLabelElements[i].id,currentLabelElements[i].classificationId)
      deleteLabelByIndex(i);
    }
    */

    //saveButtonRef.current.click();
  });


  useHotkeys('q', (): any => {
    if (getIsOnGoingLabelModelOpen()) {
      console.log("Previous and Save Hotkey");
      saveButtonRef.current.click();
      const toIndex = getCurrentIndex() - 1;
      if (toIndex < 0) {
        message.error(`index can not be under 1`, 0.8);
      } else {
        datasetImageNavigateTo(toIndex);
      }
    }
  });

  useHotkeys('e', (): any => {
    if (getIsOnGoingLabelModelOpen()) {
      console.log("Next and Save Hotkey");
      saveButtonRef.current.click();
      const toIndex = getCurrentIndex() + 1;
      if (toIndex >= getImagesCount()) {
        message.error(`index can not be bigger than total`, 0.8);
      } else {
        datasetImageNavigateTo(toIndex);
      }
    }
  });

  const handleOnRemoveAllLabel = () => {
    deleteLabelAll();
  };

  const btnMouseEvent = (index: number, id: number | null) => {
    setCurrentHoverLabelElement(index)
    setCurrentLabelId(id)
  };

  const handleOnSave = () => {
    if (onGoingDatasetType.includes("CLASSIFY")) {
      const hasNoclass = currentLabelElements.findIndex(
        (labelObj) => !labelObj.classificationId
      );
      /* Temp Remark
      if (hasNoclass >= 0) {
        return message.error("please select a classification",0.8);
      }
      */
    }

    insertDetectLabelsToImageByIdAsync(currentImage!.id, currentLabelElements);
  };

  const handleAutoLabel = async () => {
    const urlType = onGoingDatasetType === "DETECT" ? "DETECT" : "DETECT_AND_CLASSIFY";

    try {
      const res = await axios.get(`${offlineUrlExpress}/api/getmodelslist/` + urlType);
      // const res1 = await axios.get(`${offlineUrlExpress}/api/getmodelslist/DETECT`);
      // console.log(res1.data)
      setModels(res.data);
      setOpenAuto(true);
    } catch (err: any) {
      console.log(err.message);
    };

  };
  const handleCloseAuto = () => setOpenAuto(false);
  const handleOnChangeModel = (value: string) => {
    const target = models.find(data => data.modelid === value);
    setSelectModel(target);
  };
  const handleTestModelAsync = async () => {
    if (selectModel) {
      const currentClasses = classSummary;

      let classes = {};
      if (currentClasses[0]?.classlabel === 'Empty Class') {
        currentClasses.splice(0, 1);
      };
      if (currentClasses.length > 0) {
        currentClasses.reduce(
          (obj, item) => Object.assign(obj, { [item.class]: item.classlabel }), classes);
      };
      try {

        const body = {
          // ...selectModel,
          modelname: selectModel.modelname,
          type: selectModel.modeltype,
          action: "test",
          directory: "",
          cropRegion: {
            "topLeftX": 0,
            "topLeftY": 0,
            "bottomRightX": 1,
            "bottomRightY": 1
          },
          params: {
            "load_model": selectModel.modelid,
            "image_size": selectModel.params.image_size,
            "confidence": 0.5,
            "nms_threshold": 0.01,
            "startrecord": 1,
            "endrecord": 1046
          },
          datasets: [
            {
              id: currentIndex,
              "filename": currentImage?.filename,
              "url": `${currentImage?.sourceOrigin}/${currentImage?.filename}`
            }
          ],
          classes: JSON.stringify(classes)
        };
        const res = await axios.post(`${offlineUrlExpress}/api/predictmodel`, body);
        const logsUrl = res.data;
        getResult(logsUrl);

        handleCloseAuto();

      } catch (err: any) {
        console.log(err.message)
        message.error("auto label failed");
      };
    }
  };

  const getResult = async (url: string) => {
    let x = 0;
    setAutoLabeling(true);
    const interval = setInterval(async () => {
      try {
        const res = await axios.get(url);
        let getString = res.data as string;
        let finishIndex = (getString).indexOf("----test finish");
        let taskDead = getString.indexOf("task dead");
        if (finishIndex > 0) {
          clearInterval(interval);
          const startIndex = getString.indexOf(`{"image_url`);
          const result = getString.substring(startIndex, finishIndex);
          // setAutoResult(JSON.parse(result));
          autoLabelConvert(JSON.parse(result) as AutoLabelResultType);
          return
        };
        if (x >= 5 || taskDead > 0) {
          clearInterval(interval);
          message.error("auto label failed");
          setAutoLabeling(false)
        };
        x++;
      } catch (err: any) {
        message.error(err.message);
        clearInterval(interval);
      } finally {
        setAutoLabeling(false);
      };
    }, 1000);
  };

  /**
   * * 將結果轉成current label的資料並dispatch給redux
   */
  const autoLabelConvert = (data: AutoLabelResultType) => {
    const { image_url, result } = data;
    if (result.length === 0) {
      setAutoLabeling(false);
      message.warning("no results");
      return;
    };
    var img = new Image();
    img.src = image_url;

    img.onload = function () {
      let t = 1;
      const { width, height } = img;

      let newElements: LabelElement[] = currentLabelElements;
      for (let position of result) {

        if (autoSave && position.score <= scoreValue) {
          //如果小於設定範圍則跳過
          continue;
        };
        const [topX, topY, bottomX, bottomY] = position.bbox;
        const class1 = parseInt(position.class);
        let id = new Date().getTime();
        let tx = topX / width;
        let ty = topY / height;
        let bx = bottomX / width;
        let by = bottomY / height;
        let type = selectModel?.modeltype === 'DETECT' ? undefined : class1;
        //檢查是否有重複的id
        if (newElements.findIndex(ele => ele.id === id) > -1) {
          id += t;
          t++;
        };
        const element = createLabelElement(id, tx, ty, bx, by, type);
        newElements.push(element);
      };
      message.success("auto label finish");
      if (autoSave) {
        insertDetectLabelsToImageByIdAsync(currentImage!.id, newElements);
      };
      setCurrentLabelElements(newElements);
      setAutoLabeling(false);
      return;
    };
  };
  const onChangeScoreForFilter = (newValue: number | null) => {
    setScoreValue(newValue ? newValue : 0);
  };


  return (
    <Container>
      <InfoSection>
        <div>
          <h3>Dataset Type:</h3>
          <InfoP>{onGoingDatasetType}</InfoP>
        </div>
      </InfoSection>
      <InfoSection>
        <div>
          <h3>Dataset Name:</h3>
          <InfoP>{onGoingDatasetName}</InfoP>
        </div>
      </InfoSection>
      <InfoSection>
        <div>
          <h3>Image Name:</h3>
          <p style={{ wordWrap: "break-word" }}>{currentImage?.filename}</p>
        </div>
      </InfoSection>
      <InfoSection>
        <div>
          <h3>Image Source:</h3>
          <InfoP>{currentImage?.sourceOrigin}</InfoP>
        </div>
      </InfoSection>
      <InfoSection>
        <div>
          <h3>Is Labeled:</h3>
          <InfoP>{currentImage?.labeled ? "YES" : "NO"}</InfoP>
        </div>
      </InfoSection>
      <InfoSection
        extends={true}
        style={{ minHeight: "250px", overflow: "scroll" }}
      >
        <div>
          <h3>Current Labels:</h3>
        </div>

        <LabelTagsContainer>

          <>
            {currentLabelElements.map(({ id, classificationId }, index) => {

              const menu = (
                <Menu>
                  {onGoingClassifications.map(({ id, description }) => (
                    <Menu.Item
                      key={id}
                      onClick={() => { setCurrentLabelClassification(index, id) }}
                    >
                      {description}
                    </Menu.Item>
                  ))}
                </Menu>
              );
              if (onGoingDatasetType === "CLASSIFY" || onGoingDatasetType === "ABNORMAL") {
                return (
                  <Dropdown overlay={menu} placement="topLeft" arrow>
                    <Button
                      type="primary"
                      size="small"
                      style={{ margin: "2px" }}
                      key={"label-classify" + id}
                      onMouseLeave={() => btnMouseEvent(-1, null)}
                      onMouseEnter={() => btnMouseEvent(index, id)}
                      ghost={id !== currentLabelId}
                    >
                      {classificationId
                        ? onGoingClassifications.find(
                          ({ id }) => id === classificationId
                        )?.description
                        : `Pick a Class`}
                    </Button>
                  </Dropdown>
                );
              }
              if (onGoingDatasetType === "DETECT_AND_CLASSIFY") {
                return (
                  <Popconfirm
                    title="Are you sure to delete this label?"
                    onConfirm={() => onDeleteLabelConfirm(index)}
                    // onCancel={onDeleteLabelCancel}
                    okText="Yes"
                    cancelText="No"
                    key={id}
                  >
                    <Dropdown overlay={menu} placement="topLeft" arrow>
                      <Button
                        size="small"
                        type="primary"
                        // currentLabelId?"yellow":"default"
                        style={{ margin: "2px" }}
                        key={"label-classify" + id}
                        onMouseLeave={() => btnMouseEvent(-1, null)}
                        onMouseEnter={() => btnMouseEvent(index, id)}
                        ghost={id !== currentLabelId}
                      >
                        {classificationId
                          ? onGoingClassifications.find(
                            ({ id }) => id === classificationId
                          )?.description
                          : `Pick a Class`}
                      </Button>
                    </Dropdown>
                  </Popconfirm>
                );
              }
              return (
                <Popconfirm
                  title="Are you sure to delete this label?"
                  onConfirm={() => onDeleteLabelConfirm(index)}
                  // onCancel={onDeleteLabelCancel}
                  okText="Yes"
                  cancelText="No"
                  key={id}
                >
                  <Button
                    size="small"
                    type="primary"
                    style={{ margin: "2px" }}
                    onMouseLeave={() => btnMouseEvent(-1, null)}
                    onMouseEnter={() => btnMouseEvent(index, id)}
                    ghost={id !== currentLabelId}
                  >
                    {classificationId
                      ? onGoingClassifications.find(
                        ({ id }) => id === classificationId
                      )?.description
                      : `Label-${index + 1}`}
                  </Button>
                </Popconfirm>
              );
            })}

          </>
        </LabelTagsContainer>
      </InfoSection>
      <InfoSection>
        <div style={{
          display: 'flex', flexDirection: 'column', gap: 10, height: 200, width: '90%', margin: 'auto', paddingTop: 10
        }}>
          <Button
            onClick={handleOnRemoveAllLabel}
            danger
            ref={removeAllLabelButtonRef}

          >
            REMOVE ALL LABEL [W]
          </Button>
          
          {autolabelFunction &&           
            <Button
              loading={autoLabeling}
              onClick={handleAutoLabel}
              ref={autoLabelRef}

            >
              AUTO LABEL [L]
            </Button>
          }
          <Button
            loading={isLabelUpdating}
            onClick={handleOnSave}
            ref={saveButtonRef}
          >
            SAVE [S]
          </Button>
          <div>
            {currentImage?.url && onGoingDatasetId
              && <LabelSyntheticModal imageUrl={`${offlineUrlExpress}/api/get-image?url=${currentImage!.url}`} datasetId={onGoingDatasetId} imageName={onGoingDatasetName} />}

          </div>

        </div>
      </InfoSection>


      <Modal
        title="Auto Label"
        visible={openAuto}
        onOk={handleTestModelAsync}
        onCancel={handleCloseAuto}
        width={500}
      >
        <div style={{ width: 800, margin: 'auto' }}>
          <p>Model :</p>
          <Select style={{ margin: 'auto', width: 400 }} onChange={handleOnChangeModel}>
            {models.map(data =>
              <Option value={data.modelid}>{data.modelname} [{`${Object.keys(data.classes).map(cls => cls)}`}]</Option>
            )}
          </Select>
        </div>
        <div style={{ display: 'flex', width: 300, marginTop: 10 }}>

          <Slider
            disabled={!autoSave}
            style={{ width: '70%' }}
            min={0}
            max={1}
            step={0.01}
            onChange={onChangeScoreForFilter}
            value={typeof scoreValue === 'number' ? scoreValue : 0}
          />
          <InputNumber
            disabled={!autoSave}
            min={0}
            max={1}
            step={0.01}
            style={{ margin: '0 16px' }}
            value={scoreValue}
            onChange={onChangeScoreForFilter}
          />
        </div>
        <Checkbox checked={autoSave} onChange={() => setAutoSave(prev => !prev)}>
          auto filter and save from score.
        </Checkbox>
      </Modal>

    </Container>
  );
};

export default LabelImageControlPannel;
//image right bar menu