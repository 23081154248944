import { ActionTypes } from "../action-types/index";
import * as Actions from "../actions";

export interface CreateDatasetState {
  isModalOpen: boolean;
  isPending: boolean;
  name: string;
  classDescriptions: string[];
  type: string;
  error: string | string[];
  success: boolean;
  username: string;
}

const INIT_STATE: CreateDatasetState = {
  isModalOpen: false,
  isPending: false,
  type: "DETECT",
  name: "",
  classDescriptions: ["", ""],
  error: "",
  success: false,
  username: "",
};

const reducer = (state = INIT_STATE, action: Actions.CreateDatasetAction) => {
  switch (action.type) {
    case ActionTypes.START_CREATE_DATASET:
      return {
        ...INIT_STATE,
        isModalOpen: true,
      };
    case ActionTypes.CANCEL_UPDATE_DATASET:
      return {
        ...INIT_STATE,
      };
    case ActionTypes.HANDLE_DATASET_ON_CHANGE:
      return {
        ...state,
        [action.key]: action.value,
      };
    case ActionTypes.CREATE_DATASET_START:
      return {
        ...state,
        isPending: true,
      };
    case ActionTypes.CREATE_DATASET_SUCCESS:
      return {
        ...state,
        isPending: false,
        isModalOpen: false,
        success: action.success,
      };
    case ActionTypes.CREATE_DATASET_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.error,
      };
    case ActionTypes.CLEAR_CREATE_DATASET_ERROR:
      return {
        ...state,
        error: "",
      };
    default:
      return state;
  }
};

export default reducer;
