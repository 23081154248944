import {
  ArrowsAltOutlined, CloseCircleOutlined, FormOutlined, LeftCircleFilled, RedoOutlined, RightCircleFilled, ZoomInOutlined
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Menu, Popconfirm, Row, Select, Skeleton, Spin, message } from "antd";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useStore } from "react-redux";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";

import { useHotkeys } from 'react-hotkeys-hook';
import LabelImageControlPannel from "../Label-Image-Control-Pannel-DETECT";
import LabelImageDetect from "../Label-image-DETECT";
//control image scale

import { offlineUrlExpress } from "../../urls";

import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import useContextMenu from "../../hooks/useContextMenu";

const FilteredLabelSpan = styled.span<{ isHighLight: boolean }>`
border: 1px solid black;
  border-radius: 5px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 3px 5px;
  text-align: center;
  background-color: ${({ isHighLight }) =>
    isHighLight ? "lightgrey" : "unset"};
`;

const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  opacity: .99;
  top: 0;
  right: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  z-index: 999;
  background: white;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  padding: 2rem 0;
  background-color: whitesmoke;
  overflow:auto;
`;
const DisplayContainer = styled.div`
  width: 100vw;
  display: flex;
  --justify-content: space-between;
`;

const ImageInfoContainer = styled.div`
  width: 14rem;
  margin:0 5px;
  display: flex;
  flex-direction: column;
  padding: 0 5px;
`;
const ImageControllerContainer = styled.div`
  width: 15rem;
  --margin: 0 5px;
  margin-right: 10px;
  --padding: 0 15px;
  padding: 0 5px;
  --display: flex;
  --flex-direction: column; 
`;
const MyImageContainer = styled.div`
  flex: 1;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  border-radius: 5px;
  margin-right: 25px;
  --display: flex;
  --flex-direction: column;
`;

const MyNatualImage = styled.img`
  display: none;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
`;
const MyImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
`;
const NavigatController = styled.div`
  min-height:350px;
  overflow:auto;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  padding: 5px 0;
  border-radius: 5px;
  margin-top: 5px;
`;
const FilterController = styled.div`
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 20px 0 5px;
  margin-top: 5px;
  text-align: center;
`;

const ClassFilterController = styled.div`
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 20px 0 5px;
  margin-top: 5px;
  text-align: center;
`;

const DeleteController = styled.div`
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-top: 5px;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
`;
const IndexDisplayContainer = styled.div`
  h3 {
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
  }
  .index-display {
    font-size: 1rem;
    width: 100%;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
    padding: 5px 0;
    border-radius: 5px;
    div {
      display: flex;
      text-align: center;

      input {
        text-align: center;
        background-color: whitesmoke;
        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      button {
        flex: 1;
        background: lightblue;
        border-radius: 5px;
        text-align: center;
        padding: 2px 5px;
        width: 50%;
        margin: 5px auto 0;
      }
    }
  }
`;
const ScaleController = styled.div`
  width: 20%;
  height: 3.5em;
  position:absolute;
  border-radius:10px;
  top:25px;
  left:150px;
  background-color:white;
  z-index:100;
  padding:10px;
  box-shadow:0px 1px 10px 1px black;
`;

const InfoSection = styled.div<{ extends?: boolean }>`
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  h3 {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  flex-grow: ${(props) => (props.extends ? 1 : 0)};
  margin-bottom: 0.5rem;
`;
const InfoP = styled.p`
  text-align: center;
  word-wrap: break-word;
`;

export interface LabelImageDisplayerProps {
  onGoingDatasetId: number;
}

const LabelImageDisplayer: React.FC<LabelImageDisplayerProps> = ({
  onGoingDatasetId,
}) => {
  const imageContainerRef = useRef<HTMLDivElement | null>(null);
  const [dispalyImageRef, setDispalyImageRef] =
    useState<HTMLImageElement | null>(null);
  const indexInputRef = useRef<HTMLInputElement | null>(null);
  const [natureWidthAndHeight, setNatureWidthAndHeight] =
    useState<null | [number, number]>(null);
  const [containerWidthAndHeight, setContainerWidthAndHeight] = //from img ref to set img w&h of container  
    useState<null | [number, number]>(null);
  const [imageWidthAndHeight, setImageWidthAndHeight] =
    useState<null | [number, number]>(null);
  const [browsering, setBrowsering] = useState(true);
  const [isImport, setIsImport] = useState<boolean | undefined>(undefined);
  const [filterLabeled, setFilterLabeled] =
    useState<boolean | undefined>(undefined);
  const [filterSource, setFilterSource] = useState("All");

  const [classed, setClassed] =
    useState<string | undefined>(undefined);
  const [scalesImage, setScalesImage] = useState<boolean>(false);

  // const [labelSummary, setLabelSummary] = useState<string>("");
  // const [classSummary, setClassSummary] = useState<string>("");
  const { anchorPoint, show } = useContextMenu();//pop menu context control
  const [windowSize, setWindowSize] = useState([0, 0]);

  const store = useStore();

  useEffect(() => {
    setBrowsering(true);
    setFilterLabeled(undefined);
    setFilterSource("All");
    handleRefreshClassSummary();
  }, [onGoingDatasetId]);

  const {
    currentIndex,
    currentImage,
    isImageLoading,
    imagesCount,
    onGoingDatasetType,
    isDatasetImagesQueryCountPending,
    isOnGoingLabelModelOpen,
    isImageDeleting,
    currentLabelElements,
    currentDatasetId,
    classSummary,
    fromExternal,
    isLabelLastSave,
    isLabelUpdateEmpty,
    refresh,
    autoLabeling
  } = useTypedSelector((state) => state.onGoinglabel);

  useEffect(() => {
    if (indexInputRef && indexInputRef.current) {
      indexInputRef.current.value = (currentIndex + 1).toString();
    }
  }, [currentIndex]);

  useEffect(() => {
    //console.log(`fromExternal : ${fromExternal}`);
    if (fromExternal > 0) {
      setFilterLabeled(undefined);
      setClassed(undefined);
      setIsImport(undefined);
      setFilterSource("All");
    }
  }, [fromExternal]);


  useEffect(() => {
    if (refresh > 0) {
      //setFilterLabeled(undefined);
      //setClassed(undefined);
      //setIsImport(undefined);
      datasetImagesQueryingAsync(onGoingDatasetId, filterLabeled, classed, isImport, filterSource);
    }
  }, [refresh]);


  useEffect(() => {
    //console.log(`isLabelLastSave : ${isLabelLastSave} , filterLabeled : ${filterLabeled}`);

    if (isLabelLastSave > 0 && (filterLabeled === false || isImport === true)) {

      datasetImagesQueryingAsync(onGoingDatasetId, filterLabeled, classed, isImport, filterSource);
      //datasetImageFetchingAsync(onGoingDatasetId, currentIndex, filterLabeled, classed);
      let toIndex = currentIndex;

      if (currentIndex + 2 > imagesCount) {
        toIndex = imagesCount - 2;
      }

      if (toIndex < 0) {
        toIndex = 0;
      }

      datasetImageFetchingAsync(onGoingDatasetId, toIndex, filterLabeled, classed, isImport, filterSource);

    } else if (isLabelLastSave > 0 && (filterLabeled === true || isImport === false)) {
      //console.log(`isLabelUpdateEmpty ${isLabelUpdateEmpty}`)

      datasetImagesQueryingAsync(onGoingDatasetId, filterLabeled, classed, isImport, filterSource);

      let toIndex = currentIndex;

      if (isLabelUpdateEmpty > 0) {
        console.log("Refresh");
        let toIndex = currentIndex;

        if (currentIndex + 2 > imagesCount) {
          toIndex = imagesCount - 2;
        }

        if (toIndex < 0) {
          toIndex = 0;
        }
        //console.log(`imagesCount ${imagesCount} - currentIndex ${currentIndex} - currentIndex ${currentIndex}`)

        datasetImageFetchingAsync(onGoingDatasetId, toIndex, filterLabeled, classed, isImport, filterSource);
      }
    } else if (isLabelLastSave > 0) {
      let toIndex = currentIndex;
      datasetImageFetchingAsync(onGoingDatasetId, toIndex, filterLabeled, classed, isImport, filterSource);
    }


  }, [isLabelLastSave]);

  const {
    datasetImageFetchingAsync,
    toggleLabelingModel,
    datasetImageNavigateTo,
    deleteDatasetImageByIdAsync,
    datasetImagesQueryingAsync,
    setClassSummary,
    initClassSummary,
    setAutoClassLabel,
    setCurrentDatasetId,
  } = useActions();

  useEffect(() => {

    console.log(currentDatasetId, onGoingDatasetId);

    if (currentDatasetId !== onGoingDatasetId) {
      setCurrentDatasetId(onGoingDatasetId);
      setFilterLabeled(undefined);
      setClassed(undefined);
      setIsImport(undefined);
      setFilterSource("All");
      initClassSummary();
      //setFilterLabeled(undefined);
    };

  }, [onGoingDatasetId, currentDatasetId]);


  useLayoutEffect(() => {
    if (
      currentImage &&
      natureWidthAndHeight &&
      imageContainerRef &&
      imageContainerRef.current
    ) {
      const { width, height, x, y } =
        imageContainerRef.current.getBoundingClientRect();
      setContainerWidthAndHeight([width, height]);

      window.addEventListener("resize", () => {
        if (imageContainerRef && imageContainerRef.current) {
          const { width, height, x, y } =
            imageContainerRef.current.getBoundingClientRect();
          setWindowSize([window.innerHeight, window.innerWidth]);
          setContainerWidthAndHeight([width, height]);
        }
      });
    };
    return () => {
      window.removeEventListener("resize", () => {
        setContainerWidthAndHeight(null);
      });
    };
  }, [currentImage, natureWidthAndHeight]);

  useEffect(() => {

    if (containerWidthAndHeight && natureWidthAndHeight) {
      const [naturalWidth, naturalHeight] = natureWidthAndHeight;
      const [w, h] = containerWidthAndHeight;
      let desireW;
      let desireH;
      //依照比例設定圖片長寬
      if (w * (naturalHeight / naturalWidth) > h) {
        //full width overheight
        desireH = h;
        desireW = h * (naturalWidth / naturalHeight);
      } else {
        //full width not over height
        desireW = w;
        desireH = +w * (+naturalHeight / +naturalWidth);
      };
      console.log(desireW * 0.95, desireH * 0.95)
      setImageWidthAndHeight([desireW * 0.95, desireH * 0.95]);
    }
  }, [containerWidthAndHeight, natureWidthAndHeight]);

  useEffect(() => {
    datasetImagesQueryingAsync(onGoingDatasetId, filterLabeled, classed, isImport, filterSource);
  }, [filterLabeled, onGoingDatasetId, datasetImagesQueryingAsync, classed, isImport, filterSource]);

  useEffect(() => {
    //console.log("###", onGoingDatasetId, isOnGoingLabelModelOpen);
    if (onGoingDatasetId && isOnGoingLabelModelOpen) {
      datasetImageFetchingAsync(onGoingDatasetId, currentIndex, filterLabeled, classed, isImport, filterSource);
    }
  }, [
    onGoingDatasetId,
    currentIndex,
    datasetImageFetchingAsync,
    filterLabeled,
    isImageDeleting,
    classed,
    isImport,
    filterSource,
    windowSize
  ]);

  useEffect(() => {
    //console.log("###", onGoingDatasetId, isOnGoingLabelModelOpen);
    if (isOnGoingLabelModelOpen) {
      setFilterSource("All");
      datasetImageFetchingAsync(onGoingDatasetId, currentIndex, filterLabeled, classed, isImport, filterSource);
    }
  }, [
    isOnGoingLabelModelOpen,
  ]);

  const prevButtonRef: any = useRef(null);
  const nextButtonRef: any = useRef(null);
  const deleteButtonRef: any = useRef(null);

  const popImageDeleteRef: any = useRef(null);

  const getIsOnGoingLabelModelOpen = () => {
    let gstate = store.getState();
    return gstate.onGoinglabel.isOnGoingLabelModelOpen;
  }

  useHotkeys('a', (): any => {
    if (getIsOnGoingLabelModelOpen()) {
      console.log("Left Hotkey");
      prevButtonRef.current.click();
    }
  })
  useHotkeys('d', (): any => {
    if (getIsOnGoingLabelModelOpen()) {
      console.log("Right Hotkey");
      console.log(nextButtonRef);
      nextButtonRef.current.click();
    }
  })

  useHotkeys('x', (): any => {
    if (getIsOnGoingLabelModelOpen()) {
      console.log("Delete Hotkey");
      deleteButtonRef.current.click();
    }
  })

  useHotkeys('r', (): any => {
    if (getIsOnGoingLabelModelOpen()) {
      console.log("Draw Hotkey");
      setBrowsering(browsering => !browsering);
    }
  })


  useHotkeys('v', (): any => {

    /*
    var element:any=ReactDOM.findDOMNode(deleteButtonRef.current);
    console.log(element);
    var rect:any =element.getBoundingClientRect();
    console.log(rect);
    */

  })


  const handleAutoFillClass = async () => {
    let get_class_url = `${offlineUrlExpress}/api/dataset-images/detect-labels/abnormal-label/${onGoingDatasetId}`;
    setAutoClassLabel(get_class_url);
  };


  const handleRefreshClassSummary = async () => {
    let get_class_url = `${offlineUrlExpress}/api/getdatasetclassessummary/${onGoingDatasetId}`;
    //let get_label_url = `${offlineUrlExpress}/api/getdatasetlabeledsummary/${onGoingDatasetId}`;
    setClassSummary(get_class_url);
  };

  const handleIndexInputOnClick = () => {
    if (indexInputRef && indexInputRef.current) {
      const { value } = indexInputRef.current;
      let naviTo = +value;
      if (!naviTo) {
        return message.error(`index is invalid`);
      }
      if (naviTo - 1 < 0) {
        return message.error(`index can not be under 1`);
      }
      datasetImageNavigateTo(naviTo - 1);
      setScalesImage(false)
    }
  };

  const handleNavigate = (direction: "left" | "right") => {
    setScalesImage(false)
    if (direction === "left") {
      const toIndex = currentIndex - 1;
      if (toIndex < 0) {
        return message.error(`index can not be under 1`, 0.8);
      }
      return datasetImageNavigateTo(toIndex);
    }
    if (direction === "right") {
      const toIndex = currentIndex + 1;
      if (toIndex >= imagesCount) {
        return message.error(`index can not be bigger than total`, 0.8);
      }
      return datasetImageNavigateTo(toIndex);
    }
  };

  const onChangeFilterSource = (value: string) => {
    datasetImageNavigateTo(0);
    setFilterSource(value);
    console.log(`selected ${value}`);
  };

  return (
    <Container isOpen={isOnGoingLabelModelOpen}>
      {show &&
        <Menu style={{
          position: "absolute",
          left: anchorPoint.x,
          top: anchorPoint.y,
          zIndex: 9999,
          display: 'none'
        }} >
          <Menu.Item icon={<LeftCircleFilled />} onClick={() => handleNavigate("right")}>next</Menu.Item>
          <Menu.Item icon={<RightCircleFilled />} onClick={() => handleNavigate("left")}>prev</Menu.Item>
          {browsering ?
            <Menu.Item icon={<FormOutlined />} onClick={() => { setBrowsering(false); setScalesImage(false) }}>
              Draw Rectangle [r]
            </Menu.Item> :
            <Menu.Item icon={<ArrowsAltOutlined />} onClick={() => { setBrowsering(true); setScalesImage(false) }}>
              Back To Selecting [r]
            </Menu.Item>}
          <Menu.Item icon={<ZoomInOutlined />} onClick={() => { setScalesImage(prev => !prev); setBrowsering(true) }} >
            {scalesImage ? "Scaling..." : 'Scale'}
          </Menu.Item>
        </Menu>
      }
      <span
        style={{
          fontSize: "1.5rem",
          position: "fixed",
          top: "-8px",
          //right: "10px",
          left: "10px",
        }}
        onClick={toggleLabelingModel}
      >
        <CloseCircleOutlined />
      </span>
      <DisplayContainer>
        <ImageInfoContainer>
          <IndexDisplayContainer>
            <div className="index-display">
              <h3>Current Index [{currentImage?.labelindex}]</h3>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <input
                  type="number"
                  min="1"
                  max={imagesCount}
                  ref={indexInputRef}
                  defaultValue={currentIndex + 1}
                />
                <button onClick={handleIndexInputOnClick}>Go</button>
              </div>
            </div>
            <Spin spinning={isDatasetImagesQueryCountPending || isImageLoading}>
              <div className="index-display" style={{ marginTop: "5px" }}>
                <h3>Total Images</h3>
                <div
                  className="center"
                  style={{ display: "block", textAlign: "center" }}
                >
                  {imagesCount}
                </div>
              </div>
            </Spin>
          </IndexDisplayContainer>

          <NavigatController>
            <div style={{ fontSize: "3rem", textAlign: "center" }}>
              <LeftCircleFilled onClick={() => handleNavigate("left")} ref={prevButtonRef} />
              <div style={{ fontSize: "1rem", textAlign: "center" }}>
                Prev Image [a]
              </div>
            </div>
            <div style={{ fontSize: "3rem", textAlign: "center" }}>
              <RightCircleFilled onClick={() => handleNavigate("right")} ref={nextButtonRef} />
              <div style={{ fontSize: "1rem", textAlign: "center" }}>
                Next Image [d]
              </div>
            </div>
            {onGoingDatasetType.includes("DETECT") && (
              <>
                {browsering ? (
                  <div style={{ fontSize: "3rem", textAlign: "center" }}>
                    <FormOutlined onClick={() => { setBrowsering(false); setScalesImage(false) }} />
                    <div style={{ fontSize: "1rem", textAlign: "center" }}>
                      Draw Rectangle [r]
                    </div>
                  </div>
                ) : (
                  <div style={{ fontSize: "3rem", textAlign: "center" }}>
                    <ArrowsAltOutlined onClick={() => { setBrowsering(true); setScalesImage(false) }} />
                    <div style={{ fontSize: "1rem", textAlign: "center" }}>
                      Back To Selecting [r]
                    </div>
                  </div>
                )}
                <div style={{ fontSize: "3rem", textAlign: "center" }}>
                  <Button icon={<ZoomInOutlined />} shape="round" onClick={() => { setScalesImage(prev => !prev); setBrowsering(true) }} danger>
                    {scalesImage ? "Scaling..." : 'Scale'}
                  </Button>
                </div>
              </>
            )}
          </NavigatController>

          <FilterController>
            <div
              style={{
                fontSize: "1rem",
                textAlign: "center",
                marginTop: "8px",
              }}
            > Label Filter
            </div>
            <Select
              defaultValue="All"
              size="small"
              key={filterSource}
              value={filterSource}
              style={{ width: 150 }}
              //bordered={false}
              onChange={onChangeFilterSource}
              options={[
                { value: 'All', label: 'All Label' },
                { value: 'AutoRun', label: 'AutoRun Label' },
                { value: 'AutoLabel', label: 'Auto Label' },
                { value: 'Manual', label: 'Manual Label' },
                { value: 'TestResult', label: 'TestResult' },
                //{ value: 'Import', label: 'Import'},
              ]}
            />
            <div
              style={{
                fontSize: "1rem",
                textAlign: "center",
                marginTop: "8px",
              }}
            ></div>
            <FilteredLabelSpan
              isHighLight={filterLabeled === undefined}
              onClick={() => {
                datasetImageNavigateTo(0);
                setFilterLabeled(undefined);
                setClassed(undefined);
              }}
            >
              All
            </FilteredLabelSpan>
            <FilteredLabelSpan
              isHighLight={filterLabeled === false}
              onClick={() => {
                datasetImageNavigateTo(0);
                setClassed(undefined);
                setFilterLabeled(false);
              }}
            >
              Not
            </FilteredLabelSpan>
            <FilteredLabelSpan
              isHighLight={filterLabeled === true}
              onClick={() => {
                datasetImageNavigateTo(0);
                setClassed(undefined);
                setFilterLabeled(true);
              }}
            >
              Done
            </FilteredLabelSpan>
            <div
              style={{
                fontSize: "1rem",
                textAlign: "center",
                marginTop: "8px",
              }}
            >
              {filterLabeled === true
                ? "Only labeled"
                : filterLabeled === false
                  ? "Only Not labeled"
                  : "Show all"}
            </div>
            {/*
              <FilteredLabelSpan
              isHighLight={isImport===undefined}
              onClick={() => {
                datasetImageNavigateTo(0);
                setIsImport(undefined);
              }}
            >
              None
            </FilteredLabelSpan>
            <FilteredLabelSpan
              isHighLight={isImport===true}
              onClick={() => {
                datasetImageNavigateTo(0);
                setIsImport(true);
              }}
            >
              A
            </FilteredLabelSpan>

            <FilteredLabelSpan
              isHighLight={isImport === false}
              onClick={() => {
                datasetImageNavigateTo(0);
                setIsImport(false);
              }}
            >
              M
            </FilteredLabelSpan>
              <div
              style={{
                fontSize: "1rem",
                textAlign: "center",
                marginTop: "8px",
              }}
            >
              {isImport === true
                ? "Auto Label"
                : isImport === false
                ? "Manual Label"
                : "None Label"}
            </div>
              */}

          </FilterController>

          <ClassFilterController style={{ minHeight: "100px", overflowY: "scroll" }}>
            {onGoingDatasetType.includes("ABNORMAL") &&
              <Button onClick={handleAutoFillClass}>Auto Label Class</Button>
            }
            <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'center', }}>
              <h3>Class Summary</h3>
              {/* <pre>{labelSummary}</pre> */}
              {classSummary.map(item =>
                <Button size="small" style={{ width: '10rem', marginBottom: 5 }} onClick={() => {
                  setFilterLabeled(undefined); setIsImport(undefined); setFilterSource("All"); setClassed(item.classlabel); datasetImageNavigateTo(0);
                }} >
                  {item.classlabel} : {item.count} / {item.imagecount}
                </Button>
              )}
            </div>
            <Button onClick={handleRefreshClassSummary}>Refresh</Button>
          </ClassFilterController>

          <DeleteController>
            <Popconfirm
              title="Are you sure to delete this image?"
              onConfirm={() => deleteDatasetImageByIdAsync(currentImage!.id)}
              // onCancel={onDeleteLabelCancel}
              okText="Yes"
              cancelText="No"
              ref={popImageDeleteRef}
            >
              <Button
                danger
                type="primary"
                shape="round"
                style={{ width: "100%" }}
                ref={deleteButtonRef}

              // onClick={() => deleteDatasetImageByIdAsync(currentImage!.id)}
              >
                Delete [x]
              </Button>
            </Popconfirm>
          </DeleteController>
        </ImageInfoContainer>
        <ImageControllerContainer>
          <LabelImageControlPannel />
        </ImageControllerContainer>

        {/* //image setting here */}

        {currentImage ? (

          <MyImageContainer ref={imageContainerRef} style={{ paddingLeft: '1rem' }}>

            <TransformWrapper
              initialScale={1}
              initialPositionX={0}
              initialPositionY={0}
              wheel={{ disabled: false }}
              panning={{ disabled: false }}
              disabled={!scalesImage}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>

                  {scalesImage ? (
                    <ScaleController>
                      <Row>
                        <Button type="primary" style={{ marginLeft: '5px' }} onClick={() => zoomIn(0.2)}>+ Enlarge</Button>
                        <Button type="primary" style={{ marginLeft: '5px' }} onClick={() => zoomOut(0.2)}>- Shrink</Button>
                        <Button type="primary" icon={<RedoOutlined />} style={{ marginLeft: '5px' }} onClick={() => resetTransform()}>Reset</Button>
                        <CloseCircleOutlined style={{ fontSize: '20px', margin: '5px 0 0 5px' }} onClick={() => setScalesImage(false)} />
                      </Row>
                    </ScaleController>
                  ) : null}
                  <TransformComponent>
                    <MyNatualImage
                      //此圖片不會顯示
                      src={`${offlineUrlExpress}/api/get-image?url=${currentImage.url}`}
                      alt="label"
                      onLoad={(e) => {
                        const { naturalWidth, naturalHeight } = e.target as HTMLImageElement;
                        setNatureWidthAndHeight([naturalWidth, naturalHeight]);
                      }}
                    />
                    {isImageLoading ? (
                      <Spin>
                        <Skeleton.Image />
                      </Spin>
                    ) : (
                      imageWidthAndHeight && (
                        <MyImage
                          onLoad={(e) => {
                            setDispalyImageRef(e.target as HTMLImageElement);
                          }}
                          style={{
                            visibility: "hidden",
                            width: imageWidthAndHeight[0],
                            height: imageWidthAndHeight[1],
                          }}
                          src={`${offlineUrlExpress}/api/get-image?url=${currentImage.url}`}
                          alt="label"
                        />
                      )
                    )}
                    {currentImage && dispalyImageRef ? (
                      <LabelImageDetect
                        imgUrl={`${offlineUrlExpress}/api/get-image?url=${currentImage.url}`}
                        browsering={browsering}
                        dispalyImageRef={dispalyImageRef}
                        imageType={currentImage.type}
                        scalesImage={scalesImage}
                      />
                    ) : null}
                  </TransformComponent>
                </>
              )}
            </TransformWrapper>

          </MyImageContainer>

        ) : null}

      </DisplayContainer>
    </Container>
  );
};

export default LabelImageDisplayer;
//image left bar menu
