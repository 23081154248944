import { Card } from "antd";
import { useState } from "react";
import { offlineUrlExpress } from "../../urls";
import { AnnotationType } from "../Label-Synthetic-ReCircle";

interface RegionType {
    BottomRightX: number;
    BottomRightY: number;
    class: string
    TopLeftX: number
    TopLeftY: number
};

export interface SyntheticImageType {
    image: string;
    regions: RegionType[]
};

export const DrawRect = ({ rect, imageSize }: { rect: AnnotationType, imageSize: number[] }) => {
    if (rect.type === 'circle') {
        const r = rect.width;
        return <div
            style={{
                position: 'absolute', border: '2px solid yellow', zIndex: 900, borderRadius: '50%',
                left: ((rect.x - r) / imageSize[0]) * 100 + '%',
                top: ((rect.y - r) / imageSize[1]) * 100 + '%',
                width: (r * 2 / imageSize[0]) * 100 + '%',
                height: (r * 2 / imageSize[1]) * 100 + '%'
            }}
        />
    } else {
        return <div
            style={{
                position: 'absolute', border: '2px solid yellow', zIndex: 900,
                left: (rect.x / imageSize[0]) * 100 + '%',
                top: (rect.y / imageSize[1]) * 100 + '%',
                width: ((rect.width) / imageSize[0]) * 100 + '%',
                height: ((rect.height) / imageSize[1]) * 100 + '%'
            }}
        />
    }
}



const LabelSyntheticLoadImage = ({
    image,
    originAnn,
    showOrigin,
    onShow
}: {
    image: SyntheticImageType,
    originAnn: AnnotationType[],
    showOrigin: boolean,
    onShow?: (image: SyntheticImageType) => void
}) => {

    const [imageSize, setImageSize] = useState([0, 0]);

    const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const target = e.target as HTMLImageElement;
        setImageSize([target.naturalWidth, target.naturalHeight])
    };

    return (
        <Card
            hoverable
            onClick={() => onShow && onShow(image)}
        >
            <div
                style={{
                    position: 'relative', display: 'inline-block'
                }}>
                <img
                    src={`${offlineUrlExpress}/api/disg/get-image?url=${image.image}`}
                    //src={image.image}
                    alt="Synthetic"
                    style={{ width: '100%', height: 'auto', borderRadius: 20 }}
                    onLoad={handleImageLoad}
                    loading="lazy"
                />
                {image.regions.map((region, index) => (
                    <div
                        key={index}
                        style={{
                            position: 'absolute',
                            border: '2px solid red',
                            left: (region.TopLeftX / imageSize[0]) * 100 + '%',
                            top: (region.TopLeftY / imageSize[1]) * 100 + '%',
                            width: ((region.BottomRightX - region.TopLeftX) / imageSize[0]) * 100 + '%',
                            height: ((region.BottomRightY - region.TopLeftY) / imageSize[1]) * 100 + '%'
                        }}
                    />
                ))}
                {showOrigin && originAnn.map((rect, key) =>
                    <DrawRect key={key} rect={rect} imageSize={imageSize} />
                )}
            </div>

        </Card>
    )
}

export default LabelSyntheticLoadImage