
import { CompressOutlined, DeleteFilled, EditOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import { Badge, Button, Modal, Popover, Image as Preview, Select, Switch } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useCanvas } from '../../contexts/DrawerContext';
import { AnchorType, Shape } from '../../contexts/DrawerTypes';
import { cropCircle, cropPolygon, cropRect } from '../../contexts/DrawerUtils';
import ShapeCombinedBox from './ShapeCombinedBox';



const ImageModal = ({ open, onClose, imageUrl }: { open: boolean, onClose: () => void, imageUrl: string }) => {
    const handleDownloadImage = () => {
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'downloaded-image.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const [dark, setDark] = useState(false);
    return (
        <Modal
            title={<div style={{ width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h3>Preview Image</h3>
                <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                    Background:
                    <Switch checked={dark} onChange={(checked) => setDark(checked)} />
                </div>
            </div>}
            centered
            open={open} onCancel={onClose}
            width={800}
            footer={[
                <Button key='download' onClick={handleDownloadImage}>Download</Button>
            ]}>
            <div style={{ background: dark ? 'rgba(10,10,10,0.8)' : 'rgba(200,200,200,0.8)' }}>
                <TransformWrapper
                    initialScale={1}
                    wheel={{ disabled: false }}
                    pinch={{ disabled: false }}
                    maxScale={5}
                >
                    {({ }) => (
                        <React.Fragment>
                            <TransformComponent>
                                <img src={imageUrl} alt='preview' width={800} />
                            </TransformComponent>

                        </React.Fragment>
                    )}
                </TransformWrapper>
            </div>
        </Modal>
    );
};


const ShapeBox = ({
    index,
    imageSource,
    shape,
    onSelected,
    onDeleteShape,
    onSelectShape,
    onVisibleShape,
    //cancelSelect,
    labelColor
}: {

    index: number,
    imageSource: string,
    shape: Shape,
    onSelected: boolean
    onDeleteShape: (shapeId: string) => void;
    onVisibleShape: (shape: Shape) => void;
    onSelectShape: (shape: Shape) => void,
    cancelSelect?: () => void,
    labelColor?: string
}) => {

    const { canvasState, setShapeClassificationId } = useCanvas();
    const { classifications, classSummary } = canvasState;
    const [cropImageUrl, setCropImageUrl] = useState('');
    const [preview, setPreview] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);

    const [hover, setHover] = useState(false);
    const croppingCanvas = useCallback((src: string, anchors: AnchorType[]) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = (e: Event) => {
            setLoadingImage(true)
            let url = '';
            const element = e.currentTarget as HTMLImageElement;
            if (shape.type === 'polygon') {
                url = cropPolygon(element, anchors);
            };
            if (shape.type === 'rect') {
                url = cropRect(element, anchors);
            };
            if (shape.type === 'circle') {
                url = cropCircle(element, anchors);
            };
            setCropImageUrl(url);
            setLoadingImage(false);
        };
        img.src = src;
    }, [shape.type]);


    const handleClick = (e: React.MouseEvent) => {
        return onSelectShape(shape)
    };

    const handleSelectClassification = (value: number) => {
        setShapeClassificationId(shape.id, value);
    };

    useEffect(() => {
        if (shape.anchors.length !== 0) {
            return croppingCanvas(imageSource, shape.anchors)
        };
    }, [croppingCanvas, shape, imageSource]);


    const classLabel = useMemo(() => {
        const target = classifications.find(ele => ele.id === shape.classificationId);
        const summary = classSummary.find(item => item.class === target?.cid);
        return {
            label: summary?.classlabel,
            color: summary ? summary.color : undefined
        };

    }, [classifications, shape, classSummary]);

    if (shape.type === 'point') return <></>;
    return (
        <div>
            <ImageModal imageUrl={cropImageUrl} open={preview} onClose={() => setPreview(false)} />
            <Badge.Ribbon
                placement='start'
                text={`${index + 1}: ${classLabel?.label || 'Empty Class'}`}
                color={classLabel?.color || labelColor}
                style={{ zIndex: 9 }}
            >
                <div
                    onMouseOver={(e) => {
                        e.currentTarget.style.cursor = "pointer"
                    }}

                    style={{
                        display: 'flex',
                        border: onSelected || hover ? '3px solid rgb(188, 34, 230,0.8)' : '1px solid rgb(10,10,10,0.3)',
                        borderRadius: 10,
                        padding: 10, position: 'relative', zIndex: 1, background: 'white'
                    }}>

                    <div
                        onClick={handleClick}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                    >
                        {(loadingImage || !cropImageUrl) ? <></> :
                            <Preview

                                style={{ width: '80%' }}
                                src={cropImageUrl}
                                preview={false}
                            />}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 2, border: '1px solid rgba(0,0,0,0.2)', padding: 5, borderRadius: 5 }}>
                        <Button
                            onClick={() => onDeleteShape(shape.id)} size='small'
                            icon={<DeleteFilled style={{ color: '#e73227 ' }} />}
                        />
                        <Button
                            disabled={onSelected} size='small'
                            onClick={() => onVisibleShape(shape)}
                            icon={shape.visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        />
                        <Button size='small'
                            onClick={() => setPreview(true)}
                            icon={<CompressOutlined />}
                        />
                        <Popover content={
                            <div style={{ width: 240, wordWrap: 'break-word', textAlign: 'center' }}>
                                <h3>Select ClassSummary</h3>
                                <Select
                                    size='middle'
                                    onChange={handleSelectClassification}
                                    style={{ width: '100%' }}
                                    value={shape.classificationId}
                                    options={[...classifications].map(item => {
                                        return {
                                            label: item.description,
                                            value: item.id
                                        }
                                    })}
                                />
                            </div>
                        } title='Anchor JSON' trigger="click" placement='left'>
                            <Button size='small' icon={<EditOutlined />} />
                        </Popover>
                    </div>
                </div>
            </Badge.Ribbon>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                {canvasState.shapes.filter(item => item.binding === shape.id).map((bindShape, index) =>
                    <div style={{ position: 'relative' }} key={index}>
                        <div id='bind-hook' style={{
                            position: 'absolute',
                            width: 30, height: 120,
                            left: -20, top: -54, borderBottomLeftRadius: 16, zIndex: 0,
                            borderLeft: '6px solid #a04000', borderBottom: '6px solid #a04000',
                        }}></div>
                        <div style={{ position: 'relative', zIndex: 99, background: 'white' }}>
                            <ShapeCombinedBox
                                key={index}
                                index={index}
                                shape={bindShape}
                                labelColor={labelColor}
                                onSelected={bindShape.id === canvasState.selectShape?.id}
                                onDeleteShape={onDeleteShape}
                                onVisibleShape={onVisibleShape}
                                onSelectShape={onSelectShape}
                            />
                        </div>

                    </div>

                )}
            </div>

        </div>
    )
}

export default ShapeBox