
import { KonvaEventObject } from 'konva/lib/Node';
import { Stage } from 'konva/lib/Stage';
import { Vector2d } from 'konva/lib/types';
import { useEffect, useState } from 'react';
import { Circle, Group, Line } from 'react-konva';
import { AnchorType, Shape } from '../../contexts/DrawerTypes';
import { hexToRgba } from '../../contexts/DrawerUtils';


const findRectSide = (anchors: AnchorType[]) => {
    //console.log(points)
    const pointX = anchors.map(anchor => anchor.x);//取出所有x值
    const pointY = anchors.map(anchor => anchor.y);//取出所有y值
    const minX = Math.min(...pointX);
    const maxX = Math.max(...pointX);
    const minY = Math.min(...pointY);
    const maxY = Math.max(...pointY);
    return {
        left: minX,
        right: maxX,
        top: minY,
        bottom: maxY,
    }
};


const EditorPolygon = ({
    shape,
    anchors,
    rectColor,
    fill,
    handleUpdateEditShape,
    handleContext,
}: {
    shape: Shape,
    anchors: AnchorType[]
    rectColor: string,
    fill: boolean,
    handleUpdateEditShape: (anchors: AnchorType[]) => void,
    handleContext?: (e: KonvaEventObject<PointerEvent>) => void,
}) => {

    const { id, isComplete } = shape;
    const [stage, setStage] = useState<Stage | null>(null);
    const [anchorPoint, setAnchorPoint] = useState<AnchorType[]>([]);
    const handleAnchorContext = (e: KonvaEventObject<PointerEvent>) => {
        e.evt.preventDefault()
        return handleContext && handleContext(e)
    };

    const handleGroupMouseOver = (e: KonvaEventObject<PointerEvent>) => {
        e.target.getStage()!.container().style.cursor = "move";
        if (e.target.getStage()) {
            setStage(e.target.getStage());
        }
    };
    const handleGroupMouseOut = (e: KonvaEventObject<PointerEvent>) => {
        e.target.getStage()!.container().style.cursor = "default";
    };
    const groupDragBound = (pos: Vector2d) => {
        let { x, y } = pos;
        if (stage) {
            const sw = stage.width();
            const sh = stage.height();
            const { left, right, top, bottom } = findRectSide(anchors);
            if (top + y < 0) y = -1 * top;
            if (left + x < 0) x = -1 * left;
            if (bottom + y > sh) y = sh - bottom;
            if (right + x > sw) x = sw - right;
            return { x, y };
        } else {
            return { x, y }
        }
    };

    const handlePointDragMove = (e: KonvaEventObject<DragEvent>) => {
        const stage = e.target.getStage();
        if (stage && e.target._lastPos) {
            const index = e.target.index - 1;
            const pos = [e.target._lastPos.x, e.target._lastPos.y];
            if (pos[0] < 0) pos[0] = 0;
            if (pos[1] < 0) pos[1] = 0;
            if (pos[0] > stage.width()) pos[0] = stage.width();
            if (pos[1] > stage.height()) pos[1] = stage.height();
            const updatedAnchors = [...anchors];
            updatedAnchors.splice(index, 1, { index: stage.attrs.id, x: pos[0], y: pos[1] });
            setAnchorPoint(updatedAnchors);
        }
    };

    const handleDragEnd = () => {
        handleUpdateEditShape(anchorPoint);
    }

    const handleGroupDragEnd = (e: KonvaEventObject<DragEvent>) => {
        if (e.target.getAttrs().id === id) {
            const updatedAnchors = [...anchors].map(anchor => {
                return {
                    ...anchor,
                    x: anchor.x + e.target.x(),
                    y: anchor.y + e.target.y()
                }
            });
            setAnchorPoint(updatedAnchors);
            handleUpdateEditShape(updatedAnchors);
            e.target.position({ x: 0, y: 0 });
        }
    };

    useEffect(() => {
        setAnchorPoint(anchors);
    }, [id]);

    return (
        <Group
            id={id}
            draggable={isComplete}
            onMouseOut={handleGroupMouseOut}
            dragBoundFunc={groupDragBound}
            onContextMenu={handleAnchorContext}
            onDragEnd={handleGroupDragEnd}
        >
            <Line
                id={id}
                strokeWidth={3}
                scaleStrokeEnabled={false}
                onContextMenu={handleAnchorContext}
                onMouseOver={handleGroupMouseOver}
                opacity={1}
                closed={isComplete}
                fill={fill ? hexToRgba(rectColor) : "transparent"}
                stroke={rectColor}
                points={anchorPoint.flatMap((point) => [point.x, point.y])}
            />
            {anchorPoint.map((point, index) =>
                <Circle
                    key={id + index * 2}
                    id={point.index}
                    x={point.x}
                    y={point.y}
                    stroke="#eee"
                    radius={5}
                    fill={rectColor}
                    draggable
                    onMouseOver={(e) => {
                        e.target.getStage()!.container().style.cursor = "crosshair";
                    }}
                    onDragMove={handlePointDragMove}
                    onDragEnd={handleDragEnd}
                />)}
        </Group>
    )
}

export default EditorPolygon