import axios from "axios";
import { Dispatch } from "redux";
import { offlineUrlExpress } from "../../urls";
import { ActionTypes } from "../action-types/index";
import * as Action from "../actions/index";

export const login =
  (username: string, password: string) =>
  async (dispatch: Dispatch<Action.AuthAction>) => {
    dispatch({
      type: ActionTypes.LOGIN_START,
    });
    try {
      const res = await axios.post<{
        accessToken: string;
        refreshToken: string;
        username: string;
        userpermission: string;
        exp: number;
        //}>(`${offlineUrl}/auth/signin`, {
      }>(`${offlineUrlExpress}/api/auth/signin`, {
        username,
        password,
      });

      const { data } = res;

      const token = data.accessToken;
      const exp = data.exp;
      localStorage.setItem("jwt", token);
      localStorage.setItem("vision_accessToken", data.accessToken);
      localStorage.setItem("vision_refreshToken", data.refreshToken);
      dispatch({
        type: ActionTypes.LOGIN_SUCCESS,
        username: data.username,
        userpermission: data.userpermission,
        expiredDate: exp,
      });
    } catch (err: any) {
      dispatch({
        type: ActionTypes.LOGIN_ERROR,
        error: err.message,
      });
    }
  };

export const loadConfig =
  () => async (dispatch: Dispatch<Action.AuthAction>) => {
    try {
      const res = await axios.get<{
        systemlogo: number;
      }>(`${offlineUrlExpress}/api/config`);
      const { data } = res;
      dispatch({
        type: ActionTypes.LOAD_CONFIG,
        systemLogo: data.systemlogo,
      });
    } catch (err: any) {
      dispatch({
        type: ActionTypes.LOAD_CONFIG,
        systemLogo: 0,
      });
    }
  };

export const refreshToken =
  () => async (dispatch: Dispatch<Action.AuthAction>) => {
    /*   dispatch({
    type: ActionTypes.LOGIN_START,
  }); */
    try {
      const vision_refreshToken = localStorage.getItem("vision_refreshToken");

      if (vision_refreshToken) {
        const refreshToken = vision_refreshToken;
        const res = await axios.post<{
          accessToken: string;
          username: string;
          userpermission: string;
          exp: number;
          //}>(`${offlineUrl}/auth/signin`, {
        }>(`${offlineUrlExpress}/api/auth/refreshToken`, {
          refreshToken,
        });
        const { data } = res;
        const token = data.accessToken;
        const exp = data.exp;
        localStorage.setItem("jwt", token);
        localStorage.setItem("vision_accessToken", data.accessToken);
        dispatch({
          type: ActionTypes.LOGIN_SUCCESS,
          username: data.username,
          userpermission: data.userpermission,
          expiredDate: exp,
        });
      }
    } catch (err: any) {
      dispatch({
        type: ActionTypes.LOGIN_ERROR,
        error: err.response.data.message,
      });
    }
  };

export const signUp =
  (username: string, password: string) =>
  async (dispatch: Dispatch<Action.AuthAction>) => {
    dispatch({
      type: ActionTypes.SIGNUP_START,
    });
    try {
      //await axios.post(`${offlineUrl}/auth/signup`, {
      await axios.post(`${offlineUrlExpress}/api/auth/signup`, {
        username: username.trim(),
        password: password.trim(),
      });
      dispatch({
        type: ActionTypes.SIGNUP_SUCCESS,
      });
    } catch (err: any) {
      dispatch({
        type: ActionTypes.SIGNUP_ERROR,
        error: err.response.data.message,
      });
    }
  };

export const logout = (): Action.Logot => {
  localStorage.removeItem("jwt");
  localStorage.removeItem("vision_accessToken");
  localStorage.removeItem("vision_refreshToken");
  return {
    type: ActionTypes.LOGOUT,
  };
};

export const cleanSignUp = (): Action.ClearSignUp => ({
  type: ActionTypes.CLEAR_SIGNUP,
});
export const cleanError = (): Action.ClearError => ({
  type: ActionTypes.CLEAR_ERROR,
});
