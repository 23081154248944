
import { AnchorType, Shape } from '../../contexts/DrawerTypes';
import PainterCircle from './PainterCircle';
import PainterPoint from './PainterPoint';
import PainterPolygon from './PainterPolygon';
import PainterRect from './PainterRect';

const PainterMiddleware = ({
  shape, rectColor, fill, points, nextPoint, radius, handleSelect, handleCompleted, handleContext
}: {
  shape: Shape,
  points: AnchorType[],
  nextPoint: { x: number, y: number } | null
  rectColor: string,
  fill: boolean,
  radius: number,
  handleSelect: () => void,
  handleCompleted: () => void,
  handleContext?: () => void
}) => {


  const expendPoint = nextPoint ? points.concat([{ index: '-1', x: nextPoint.x, y: nextPoint.y }]) : points;

  switch (shape.type) {
    case 'circle':
      return <PainterCircle
        shape={shape}
        points={expendPoint}
        rectColor={rectColor}
        fill={fill}
        onSelected={handleSelect}
        onCompleted={handleCompleted}
        onContext={handleContext}
      />
    case 'bbox':
    case 'rect':
      return <PainterRect
        shape={shape}
        points={expendPoint}
        rectColor={rectColor}
        fill={fill}
        onSelected={handleSelect}
        onCompleted={handleCompleted}
        onContext={handleContext} />;
    case 'polygon':
      return <PainterPolygon
        shape={shape}
        points={expendPoint}
        rectColor={rectColor}
        fill={fill}
        onSelected={handleSelect}
        onCompleted={handleCompleted}
        onContext={handleContext}
      />;
    case 'point':
      return <PainterPoint
        shape={shape}
        points={expendPoint}
        radius={radius}
        rectColor={rectColor}
        onSelected={handleSelect}
        onCompleted={handleCompleted}
        onContext={handleContext}
      />
    default:
      return null;
  }
}

export default PainterMiddleware 