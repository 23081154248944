
import { KonvaEventObject } from "konva/lib/Node";
import { useMemo } from "react";
import { Circle, Group } from "react-konva";
import { AnchorType, Shape } from "../../contexts/DrawerTypes";
import { hexToRgba } from "../../contexts/DrawerUtils";


const allowR = 10;//半徑至少要的大小

const PainterCircle = ({
    shape,
    rectColor,
    fill,
    points,
    onSelected,
    onContext
}: {
    shape: Shape,
    rectColor: string,
    fill: boolean,
    points: AnchorType[]
    onSelected?: () => void;
    onCompleted?: () => void;
    onContext?: (e: KonvaEventObject<PointerEvent>) => void,
}) => {

    const { id, isComplete, visible } = shape;

    const handleAnchorContext = (e: KonvaEventObject<PointerEvent>) => {
        e.evt.preventDefault()
        return onContext && onContext(e)
    };

    const circlePoint = useMemo(() => {
        const top = points[0];
        const bottom = points.length > 1 ? points[1] : { x: 0, y: 0 };

        return {
            x: top.x,
            y: top.y,
            r: bottom.x
        }
    }, [points]);

    return (
        <Group visible={visible} >
            <Circle

                id={id}
                x={circlePoint.x}
                y={circlePoint.y}
                radius={circlePoint.r}
                stroke={rectColor}
                fill={fill ? hexToRgba(rectColor) : "transparent"}
                strokeWidth={3}
                onClick={onSelected}
                onContextMenu={handleAnchorContext}

            />
            {!isComplete &&
                <Circle
                    x={circlePoint.x}
                    y={circlePoint.y}
                    radius={5}
                    fill={(points.length > 1 && circlePoint.r > allowR) ? rectColor : 'red'}

                />
            }
        </Group>
    )
}

export default PainterCircle