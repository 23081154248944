import { DeleteFilled } from '@ant-design/icons';

import { Button, Modal, Select } from 'antd';
import { useMemo } from 'react';
import { useCanvas } from '../../contexts/DrawerContext';

const ShapeEditMenu = ({ shapeId, x, y, show, onClose }: {
    shapeId: string,
    x: number,
    y: number,
    show: boolean,
    onClose: () => void,
}) => {

    const { canvasState, deleteShapeById, setShapeClassificationId } = useCanvas();
    const { classifications, shapes } = canvasState;

    const currentRect = useMemo(() => {
        return shapes.find(shape => shape.id === shapeId);
    }, [shapes, shapeId]);

    const handleSelectClassification = (value: number) => {
        setShapeClassificationId(shapeId, value);
    };
    const handleDelete = (shapeId: string) => {
        deleteShapeById(shapeId)
    };

    return (
        <div style={{
            position: 'absolute', left: x - 50, top: y, zIndex: 9999, display: show ? 'block' : 'none',
            width: 240, borderRadius: 5, background: 'white', flexDirection: 'column', gap: 5,
        }}>
            <div style={{ padding: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center' }}>
                    <h3>ClassSummary</h3>
                    <Select
                        size='middle'
                        onSelect={handleSelectClassification}
                        style={{ width: '100%' }}
                        value={currentRect?.classificationId}
                        options={[...classifications].map(item => {
                            return {
                                label: item.description,
                                value: item.id
                            }
                        })}
                    />
                </div>
            </div>

            <div style={{ padding: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                    <Button onClick={onClose}>
                        OK
                    </Button>
                    <Button danger icon={<DeleteFilled />} onClick={() => handleDelete(shapeId)}>
                        Delete
                    </Button>
                </div>
            </div>

        </div>
    )
}

export default ShapeEditMenu


export const EmptyModal = ({
    shapeId, onClose
}: {
    shapeId: string, onClose: () => void
}) => {
    const { canvasState, setShapeClassificationId } = useCanvas();
    const { shapes, classifications } = canvasState;

    const currentRect = useMemo(() => {
        return shapes.find(shape => shape.id === shapeId);
    }, [shapes, shapeId]);

    const handleSelectClassification = (value: number) => {
        setShapeClassificationId(shapeId, value);
    };

    return <Modal
        title='Pick a Class'
        open={Boolean(shapeId)}
        onCancel={onClose}
        onOk={onClose}
        centered
    >
        <Select
            size='middle'
            onSelect={handleSelectClassification}
            style={{ width: '100%' }}
            value={currentRect?.classificationId}
            options={[...classifications].map(item => {
                return {
                    label: item.description,
                    value: item.id
                }
            })}
        />

    </Modal>
};