import { KonvaEventObject } from 'konva/lib/Node';
import { Circle } from "react-konva";
import { AnchorType, Shape } from '../../contexts/DrawerTypes';

const PainterPoint = ({
    shape,
    rectColor,
    points,
    radius,
    onSelected,
    onContext
}: {
    shape: Shape,
    rectColor: string,
    points: AnchorType[],
    radius: number,
    onSelected?: () => void;
    onCompleted?: () => void;
    onContext?: (e: KonvaEventObject<PointerEvent>) => void,
}) => {

    const center = points[0]

    return (
        <Circle x={center.x} visible={shape.visible} y={center.y} radius={radius} fill={rectColor} stroke={'black'} onClick={onSelected} />
    )
}

export default PainterPoint